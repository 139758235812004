import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {
    AboutFive,
    AboutFour,
    AboutThree, BrandTwo,
    ContactTwo,
    FeatureTwo,
    FooterOne,
    HeaderOneCopy,
    ProcessTwo, TestimonialTwo
} from '../containers';
import productsData from '../data/products.json';


const features = [
    {
        title: "Reliable Infrastructure",
        image: "images/icon/fe-2-2.png",
        text: "Our cloud hosting services provide a stable and secure infrastructure, ensuring that your applications and data are always accessible and protected."
    },

    {
        title: "Scalable Resources",
        image: "images/icon/process-1-2.png",
        text: "Easily adjust your resources to match your business needs with our scalable cloud hosting solutions, allowing you to grow without limits."
    },
    {
        title: "High Performance",
        image: "images/icon/sr-icon-3-4.png",
        text: "Experience superior performance with our cloud hosting services, featuring fast load times and optimal resource management to keep your applications running smoothly."
    },
]


const aboutFourData = {
    title: 'Make Our Cloud Hosting Your Choice',
    subtitle: 'TRUSTED SERVICE FROM A TRUSTED PARTNER',
    images: [
        '/images/cloud/cloud-hosting-2.png',
        '/images/cloud/cloud-hosting-1.png',
    ],
    points: [
        {
            title: "Dependable Performance",
            text: "Our cloud hosting infrastructure ensures high reliability and uptime, providing consistent performance for your critical applications and websites."
        },
        {
            title: "Flexible and Scalable Solutions",
            text: "Our cloud hosting services adapt to your business needs, allowing you to easily scale resources up or down as required, ensuring you only pay for what you use."
        },
        {
            title: "Comprehensive Security",
            text: "We prioritize your data's security with advanced measures and continuous monitoring, protecting your business from potential threats and ensuring compliance with industry standards."
        }
    ]


}

const CloudHosting = () => (
    <Fragment>
        <Seo title="Cloud Hosting"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="Cloud Hosting"
                    pageSubTitle={"Seamless, Scalable, and Secure Hosting Solutions"}
                    pageDesc={"Our cloud hosting services offer unparalleled scalability, reliability, and security."}
                    bgImage="images/bg/bg-cloud.png"/>


        <FeatureTwo features={features} title={"What Our Solutions Bring To You"} subTitle={"Expert Developers"}
                    className="space-top mb-50" featuresHeightClass={"ht-425"}/>
        <AboutFour data={aboutFourData} className="space space-top space-bottom mt-5"/>


        <FooterOne/>

        <ScrollTopBtn/>
    </Fragment>
);

export default CloudHosting;