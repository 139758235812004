import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {
    AboutSeven,
    BlogFour,
    CtaOne,
    FooterOne,
    HeaderOneCopy,
    ServiceFour,
    TeamTwo,
    TestimonialThree
} from '../containers';

const About = ()=> (
  <Fragment>
    <Seo title="About Us" />
    <HeaderOneCopy />
    <Breadcrumb
        pageName="About Us"
        pageSubTitle={"Discover Our Story and Mission"}
        pageDesc={"Learn more about our organization's history, values, and goals, connect with our brand on a deeper level." +
            " Adjust as needed to reflect the unique aspects of our company's identity and narrative."}
        bgImage="images/breadcumb/breadcumb-bg.jpg" />
    <ServiceFour className="space-top space-extra-bottom"/>
    <AboutSeven/>
    {/*<TeamTwo className="space-top space-extra-bottom"/>*/}
    <TestimonialThree className="space-top space-extra-bottom"/>
    <CtaOne className="space"/>
    <BlogFour className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default About;