import React, {Fragment} from 'react';
import {ScrollTopBtn, Seo} from '../components';
import {
    AboutOne,
    BlogTwo,
    BrandOne, ContactOne,
    CtaOne,
    FaqOne,
    FeatureOne,
    FooterOne, HeaderOneCopy,
    HeroOne,
    ProcessOne,
    ServiceOne,
    TestimonialOne,
} from '../containers';

const HomeOne = () => (
    <Fragment>
        <Seo title="eSyncross"/>
        <HeaderOneCopy/>
        <HeroOne/>
        <div className="background-image" style={{backgroundImage: "url(images/bg/ab-bg-1-1.jpg)"}}>
            <FeatureOne className="space-top space-extra-bottom"/>
            <AboutOne className="space-bottom"/>
        </div>
        <ServiceOne className="space-top space-extra-bottom services-offering"/>
        <CtaOne className="space"/>
        <ProcessOne className="space-top space-extra-bottom"/>
        <FaqOne className={"why-choose-us"}/>
        <TestimonialOne className="space-top space-extra-bottom customers-feedback"/>
        <BrandOne className="space-bottom"/>
        <ContactOne/>
        <BlogTwo className="space-top space-extra-bottom latest-articles"/>
        <FooterOne/>
        <ScrollTopBtn/>
    </Fragment>
);

export default HomeOne;