import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Blog, Pagination, SecSubTitle} from '../../components/';
import SidebarOne from '../Sidebars/SidebarOne';

// Blog Data
import blogdata from '../../data/blog.json';

const BlogOne = ({className}) => {

    const [selected, setSelected] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedCat, setSelectedCat] = useState(null);
    const [blogsToView, setBlogsToView] = useState(blogdata);
    const handleSearch = (text) => {
        setSelected(0)
        setSearchText(text)
        let filteredData = blogdata?.filter(blog =>
            blog?.title?.toLowerCase()?.includes(text?.toLowerCase()) ||
            blog?.text?.toLowerCase()?.includes(text?.toLowerCase()))

        setBlogsToView(filteredData)
    }
    const handleTag = (tag) => {
        let tempTag = tag === selectedTag ? null : tag
        setSelected(0)
        setSearchText(tag)
        let filteredData = (tempTag === null) ? blogdata : blogdata?.filter(blog =>
            blog?.title?.toLowerCase()?.includes(tempTag?.toLowerCase()) ||
            blog?.text?.toLowerCase()?.includes(tempTag?.toLowerCase())
        )
        setBlogsToView(filteredData)
    }
    const handelCat = (cat) => {
        let tempCat = cat === selectedCat ? null : cat
        setSelected(0)
        setSelectedCat(tempCat)
        let filteredData = tempCat === null ? blogdata : blogdata?.filter(blog =>
            blog.categories.map(cat => cat.toLowerCase()).includes(tempCat.toLowerCase())
        )

        setBlogsToView(filteredData)
    }

    const clearFilter = () => {
        setSelected(0)
        handleSearch("")
        setSelectedTag(null)
        setSelectedCat(null)
    }

    return (
        <section className={`vs-blog-wrapper ${className || ''}`}>
            <div style={{overflowY: "visible !important"}} className="container">
                <div className="row gx-40">
                    <div className="col-lg-8">
                        {blogsToView.slice(4 * selected, 4 * selected + 4).map(post => (
                            <Blog className="blog-single" key={post.id}>
                                <Blog.Image path={`/blog-details/${post.id}`} src={post.images[0]}/>
                                <Blog.Body>
                                    <Blog.Meta>
                                        <Link to="/blog"><i className="far fa-calendar"/>{post.date}</Link>
                                        <Link to="/blog"><i className="fal fa-user"/>{post.authorName}</Link>
                                    </Blog.Meta>
                                    <Blog.Title path={`/blog-details/${post.id}`}>{post.title}</Blog.Title>
                                    <p>{post.text.slice(0, 250)}...</p>
                                    <Link to={`/blog-details/${post.id}`} className="link-btn">Read Details <i
                                        className="far fa-arrow-right"></i></Link>
                                </Blog.Body>
                            </Blog>
                        ))}
                        <Pagination
                            disabled={false}
                            count={Math.ceil(blogsToView?.length / 4)}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </div>
                    <div className="col-lg-4 position-relative">
                        <div
                            onClick={clearFilter}
                            style={{position: 'absolute', top: -25, right: 7, cursor: "pointer",}}>
                            <SecSubTitle className={"breadcumb_headline d-flex gap-2 align-items-center"}>Clear
                                Filters <i className={'fas fa-trash'}/></SecSubTitle>
                        </div>
                        <SidebarOne
                            isDetails={false}
                            searchText={searchText} selectedCat={selectedCat} selectedTag={selectedTag}
                            handleSearch={handleSearch} handleTag={handleTag} handelCat={handelCat}/>
                    </div>
                </div>

            </div>
        </section>
    )
};

export default BlogOne;