import React from 'react';
import MenuItem from "./MenuItem";

// Menu Data
import menuLinks from "../../data/MenuLinks.json";
import {Link} from "react-router-dom";
import {Logo} from "../index";

const MainMenu = ({ className }) => (
  <nav className={`main-menu ${className || ''}`}>
    <ul className={'d-flex align-items-center'}>
        <Link to={'/'} style={{width:175,marginBottom:8,marginRight:15}}><Logo image="/images/logo.png" /></Link>
      {menuLinks.map((item, index) => (
        <MenuItem key={index} item={item} />
      ))}
    </ul>
  </nav>
);

export default MainMenu;