import React from 'react';
import Slider from 'react-slick';
import {Button, SecSubTitle, SecTitle, ServiceBoxThree, TitleWrap} from '../../components';

// Service Data
import serviceData from '../../data/service.json';
import ServiceBoxThreeDynamic from "../../components/ServiceBox/ServiceBoxThreeDynamic";


const ServiceThreeDynamic = ({className, data}) => {

    const settings = {
        autoplay: true,
        autoplaySpeed: 8000,
        infinite: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className={`background-image ${className || ''}`}
             style={{backgroundImage: 'url(images/bg/sr-bg-3-1.jpg)'}}>
            <div className="container">
                <div className="row justify-content-center justify-content-lg-between align-items-center">
                    <div className="col-md-12 col-lg-12 text-center text-center">
                        <TitleWrap>
                            <SecSubTitle><i className="fas fa-bring-forward"/>{data.subtitle}</SecSubTitle>
                            <SecTitle className="h1 text-capitalize">{data.title}</SecTitle>
                        </TitleWrap>
                    </div>
                </div>
                <Slider className="row" {...settings}>
                    {data.points.map(item => (
                        <div className="col-md-6 col-lg-4" key={item.id}>
                            <ServiceBoxThreeDynamic
                                points={item.subPoints}
                                icon={item.icon}
                                title={item.title}
                                text={item.text}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default ServiceThreeDynamic;