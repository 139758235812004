import React from 'react';
import {Button, ImageBoxFive, InfoMedia, List, SecSubTitle, SecTitle} from '../../components';

const AboutFive = ({title, subTitle, description, number, mediaTitle, bottomList, buttonPath,images, ...restProps})=> (
  <div {...restProps}>
    <div className="container">
      <div className="row flex-row-reverse">
        <div className="col-lg-6 mb-30 pb-10 pb-lg-0">
          <ImageBoxFive
            imageOne={images[0]}
            imageTwo={images[1]}
            imageThree={images[2]}
          />
        </div>
        <div className="col-lg-6 mb-30">
          <SecSubTitle><i className="fas fa-bring-forward"/>{subTitle}</SecSubTitle>
          <SecTitle className="text-capitalize h1">{title}</SecTitle>
          <InfoMedia className="exp-media" classNameIcon={"wd-100"}
            number={number}
            title={mediaTitle}
            info={description}
          />
          <List className="list-style1">
            {bottomList.map(o => <List.Item><i className="far fa-check-circle"/> {o}</List.Item>)}
          </List>
          {/*<Button path={buttonPath}>Learn More<i className="far fa-arrow-right"/></Button>*/}
        </div>
      </div>
    </div>
  </div>
);

export default AboutFive;