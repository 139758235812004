import React, {useEffect, useState} from 'react';
import {Button, HeaderSticky, Logo} from '../../components';
import './headerStyles.css';
import {Link} from "react-router-dom";

const HeaderOneCopy = () => {
    useEffect(() => {
        const changeButtonBehavior = (e) => {
            const items = document.querySelectorAll(".esyncross-navbar__item");
            items.forEach(item => {
                const button = item.querySelector(".esyncross-navbar__dropdown");
                if (button && !button.classList.contains("esyncross-navbar__leaf")) {
                    if (e.matches) {
                        button.dataset.check = "javascript:void(0);";
                    } else {
                        button.href = button.dataset.href;
                    }
                }
            });
        };

        const buttons = document.querySelectorAll(".esyncross-navbar__item .esyncross-navbar__dropdown");
        const mql = window.matchMedia("(max-width: 920px)");

        buttons.forEach(btn => btn.dataset.href = btn.href);

        let currentTab = null;
        changeButtonBehavior(mql);

        mql.addEventListener ? mql.addEventListener("change", changeButtonBehavior) : mql.addListener(changeButtonBehavior);

        const ITEM_ACTIVE_CLASS = "esyncross-navbar__item--active";
        const navItems = document.querySelectorAll(".esyncross-navbar__item");
        const hamburger = document.querySelector(".esyncross-navbar__hamburger");
        const navbar = document.querySelector(".esyncross-navbar");
        const logo = document.querySelector(".esyncross-navbar__logo");

        document.addEventListener("click", (e) => {
            const active = document.querySelector(`.${ITEM_ACTIVE_CLASS}`);
            if (active && !active.contains(e.target)) {
                active.classList.remove(ITEM_ACTIVE_CLASS);
            }
        });

        if (logo) {
            logo.addEventListener("click", () => {
                document.body.classList.remove("no-scroll");
            });
        }

        if (hamburger) {
            hamburger.addEventListener("click", () => {
                hamburger.classList.toggle("is-active");
                navbar.classList.toggle("esyncross-navbar--active");
                document.body.classList.toggle("no-scroll");
            });
        }

        navItems.forEach(item => {
            const anchor = item.querySelector("a");
            const panel = item.querySelector(".esyncross-navbar__panel");

            if (anchor && panel) {
                anchor.addEventListener("mouseenter", () => {
                    if (window.innerWidth >= 920) {
                        item.classList.add(ITEM_ACTIVE_CLASS);
                        if (currentTab && currentTab !== item) currentTab.classList.remove(ITEM_ACTIVE_CLASS);
                        currentTab = item;
                    }
                });

                anchor.addEventListener("click", (e) => {
                    if (window.innerWidth < 920) {
                        e.preventDefault();
                        item.classList.toggle(ITEM_ACTIVE_CLASS);
                        if (currentTab && currentTab !== item) currentTab.classList.remove(ITEM_ACTIVE_CLASS);
                        currentTab = item;
                    }
                });
            } else if (anchor) {
                anchor.addEventListener("mouseenter", () => {
                    item.classList.add(ITEM_ACTIVE_CLASS);
                    if (currentTab && currentTab !== item) currentTab.classList.remove(ITEM_ACTIVE_CLASS);
                    currentTab = item;
                });

                anchor.addEventListener("mouseleave", () => {
                    if (currentTab) currentTab.classList.remove(ITEM_ACTIVE_CLASS);
                });
            }

            if (panel) {
                panel.addEventListener("mouseleave", () => {
                    if (currentTab) currentTab.classList.remove(ITEM_ACTIVE_CLASS);
                });
            }
        });

        document.querySelectorAll(".esyncross-navbar__menu a").forEach(node => {
            node.addEventListener("click", (event) => {
                if (event.currentTarget.dataset.check !== "javascript:void(0);") {
                    if (hamburger) hamburger.classList.remove("is-active");
                    if (navbar) navbar.classList.remove("esyncross-navbar--active");
                    document.body.classList.remove("no-scroll");
                }
            });
        });
    }, []);
    const mainItems = [
        {
            title: 'Solutions',
            link: "#",
            subItems: [
                {
                    title: 'ERP Solutions',
                    link: '/services',
                    subSubItems: [
                        {
                            title: 'SAP Business One',
                            link: '/sap-business-one',
                            description: 'Designed for small to midsize businesses'
                        },
                        {
                            title: 'Intercompany for SAP Business One',
                            link: '/sap-business-one-inter-company',
                            description: 'Multi-company transactions automation'
                        }
                    ]
                },
                {
                    title: 'Automation for SAP Business One',
                    link: '#',
                    subSubItems: [
                        {
                            title: 'Share files and reports by Email',
                            link: '#',
                            description: 'Automatically sharing files and reports via email.'
                        },
                        {
                            title: 'Time Attendance & Employees Expense',
                            link: '#',
                            description: 'Integrated solution with HR Module'
                        },
                        {
                            title: 'Online Sales & Purchase Portals',
                            link: '#',
                            description: "Streamlined online sales and purchase management."
                        },
                        {
                            title: 'Payments Processing by bank Integration',
                            link: '#',
                            description: 'Secure tool communicates with banks portal'
                        },
                        {
                            title: 'Planet Tax Free Integration ',
                            link: '#',
                            description: 'Tax free shopping and VAT refund made easy'
                        },
                        {
                            title: 'Approvals By WhatsApp & Email',
                            link: '#',
                            description: 'Extending and enhancing SAP approvals reach'
                        }
                    ]
                },
                {
                    title: 'Only From eSyncross',
                    link: '#',
                    subSubItems: [
                        {
                            title: 'Warehouse Management System (EVO-WMS)',
                            link: '/EVO-WMS',
                            description: 'A specialized solution for efficient warehouse operations'
                        },
                        {
                            title: 'BizVault',
                            link: '/BizVault',
                            description: 'A secure and centralized digital repository.'
                        },
                        {
                            title: 'Customs Addon',
                            link: '/customs-addon',
                            description: 'Bill of Entry & Exit & Customs Documents'
                        }
                    ]
                }
            ]
        },
        {
            title: 'Services',
            link: "#",
            subItems: [
                {
                    title: 'SAP Business One',
                    link: '#',
                    subSubItems: [
                        {
                            title: 'Transformation & Implementation',
                            link: '/sapb1-implementation',
                            description: 'Migrate to SAP B1 based on Best Practises'
                        },
                        {
                            title: 'Integration',
                            link: '/sapb1-integration',
                            description: 'Integrating SAP B1 with any 3rd party Apps'
                        },
                        {
                            title: 'Addons Development',
                            link: '/sapb1-addon',
                            description: 'Extend existing function and Create new processes'
                        },
                        {
                            title: 'Business Analytics',
                            link: '/business-analytics',
                            description: 'Advanced reports and dashboards'
                        }
                    ]
                },
                {
                    title: 'Development Services',
                    link: '#',
                    subSubItems: [
                        {
                            title: 'Solution Architecture',
                            link: '/solution-architecture',
                            description: 'Framework for designing IT solutions.'
                        },
                        {
                            title: 'Mobile Applications',
                            link: '/mobile-applications',
                            description: 'Software designed for mobile devices'
                        },
                        {
                            title: 'Web Applications',
                            link: '/web-applications',
                            description: 'Internet-based software applications.'
                        }
                    ]
                },
                {
                    title: 'Cloud Management',
                    link: '#',
                    subSubItems: [
                        {
                            title: 'Cloud Hosting',
                            link: '/cloud-hosting',
                            description: 'Hosting services delivered via cloud.'
                        },
                        {
                            title: 'Cloud Migration',
                            link: '/cloud-migration',
                            description: 'Moving applications to cloud infrastructure'
                        }
                    ]
                }
            ]
        },
        {title: 'Careers', link: "/Careers"},
        {title: 'Blog', link: "/blog"},
        {title: 'Learning Hub', link: "/learningHub"}
    ];

    const renderNavbarItems = () => {
        return mainItems.map((item, index) => (
            <li key={index} className="esyncross-navbar__item main_nav_items">
                <Link to={item?.link} className="esyncross-navbar__button esyncross-navbar__dropdown">
                    <span className="item-title">
                        {item.title}
                        {item?.subItems && (
                            <span className="links">
                                <i className="bx bxs-chevron-down htmlcss-arrow arrow links"/>
                            </span>
                        )}
                    </span>
                </Link>
                {item?.subItems && (
                    <div className="esyncross-navbar__panel">
                        <div className="esyncross-navbar__panel-wrapper">
                            <div className="esyncross-navbar__categories">
                                {item?.subItems?.map((subItem, index1) => (
                                    <div key={index1} className="esyncross-navbar__description rcorners">
                                        <h2>
                                            <strong>{subItem.title}</strong>
                                        </h2>
                                        <hr className="new1"/>
                                        {subItem?.subSubItems?.map((subSubItem, idx) => (
                                            <Link to={subSubItem.link}>
                                                <div className={"subitem-navbar"} key={idx}>
                                                    <strong>
                                                        <Link to={subSubItem.link}>
                                                            {subSubItem.title}
                                                        </Link>
                                                    </strong>
                                                    <br/>
                                                    <Link
                                                        to={subSubItem.link}
                                                        style={{
                                                            textDecoration: "none",
                                                            color: "#313232",
                                                            fontWeight: "normal",
                                                            fontSize: 14
                                                        }}
                                                    >
                                                        {subSubItem.description}
                                                    </Link>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </li>
        ));
    };

    return (
        <HeaderSticky>
            <div className="esyncross-header-area">
                <header className="esyncross-header">
                    <nav className="esyncross-navbar content-wrapper">
                        <Logo image="/images/logo.png" className="esyncross-navbar__logo"/>
                        <div className="esyncross-navbar__mobile">
                            <button
                                className="hamburger hamburger--squeeze esyncross-navbar__hamburger"
                                type="button"
                            >
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"/>
                                </span>
                            </button>
                        </div>
                        <div className="esyncross-navbar__content">
                            <ul className="esyncross-navbar__menu">
                                {renderNavbarItems()}
                            </ul>
                            <div className="esyncross-navbar__end">
                                <Button
                                    path="/contact"
                                    style={{height: 'auto', padding: '10px 16px', marginTop: 8}}
                                >
                                    Contact Sales
                                </Button>
                                <Button
                                    path="/be-partner"
                                    className="style7"
                                    style={{height: 'auto', padding: '8px 16px', marginTop: 8}}
                                >
                                    Become a Partner
                                </Button>
                            </div>
                        </div>
                    </nav>
                </header>
            </div>
        </HeaderSticky>
    );
};

export default HeaderOneCopy;
