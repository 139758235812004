import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {AboutFour, AboutSeven, AboutThree, ContactTwo, FeatureTwo, FooterOne, HeaderOneCopy} from '../containers';
import SAPB1Video from "../containers/Testimonials/SAPB1Video";
import AboutSix from "../containers/Abouts/AboutSix";
import AboutEight from "../containers/Abouts/AboutEight";
import AboutSevenCopy from "../containers/Abouts/AboutSevenCopy";
import AboutFourCopy from "../containers/Abouts/AboutFourCopy";




const aboutFourData = {
    title: 'Rely on eSyncross',
    subtitle: 'TRUSTED, EXPERIENCED, GLOBAL',
}
const aboutSevenData = [
    {
        flip: false,
        title: 'Comply With Policies and Regulatory Mandates',
        subtitle: 'REDUCE RISK',
        imgPath: 'images/esyncross/eSyncross (1).jpeg',
        bottomList: ["Flag and automate intercompany transactions",
            "Prepare intercompany financial reports with ease",
            "Safeguard the integrity of every financial report"
        ],
        description: 'Companies with multiple business entities must comply with intercompany accounting standards. InterCompany for SAP Business One helps businesses remain compliant.'


    },
    {
        flip: true,
        title: 'Eliminate Redundant Data Entry',
        subtitle: 'AVOID ERRORS',
        imgPath: 'images/esyncross/eSyncross (35).jpeg',
        bottomList: ["Allow transactional data to flow seamlessly between financial systems",
            "Avoid missed steps and inaccurate data entry",
            "Streamline collaboration between teams"
        ],
        description: 'A simple multi-entity transaction can splinter into manual, redundant data entry tasks. InterCompany for SAP Business One automates those tasks for you, preventing mistakes and ensuring no steps are missed.'


    },
    {
        flip: false,
        title: 'Provide On-Demand Access to Accurate Financial Data',
        subtitle: 'MOVE FASTER',
        imgPath: 'images/esyncross/eSyncross (41).jpeg',
        bottomList: ["Get access to accurate financial data anytime and from anywhere",
            "Respond to business conditions in real-time",
            "Outperform the competition"
        ],
        description: 'Delaying decisions until month-end puts your company at a competitive disadvantage. InterCompany for SAP Business One empowers teams to respond confidently to changes on the fly.'
    },
    {
        flip: true,
        title: 'Close the Books Faster',
        subtitle: 'ACCELERATE MONTH-END',
        imgPath: 'images/esyncross/eSyncross (47).jpeg',
        bottomList: ["Automate and streamline month-end consolidations, eliminations, and reporting",
            "Eliminate spreadsheet calculations",
            "Prevent the mistakes that cause time-consuming troubleshooting delays"
        ],
        description: 'Using spreadsheets to perform multi-entity consolidations is inefficient and error-prone. InterCompany for SAP Business One automates consolidations and eliminations so you can close the books faster.'
    },
]
const data = [
    {
        id: 'tab1',
        title: 'Master Data Replication',
        subtitle: '',
        icon: 'images/icon/sr-icon-3-1.png',
        image: 'images/esyncross/eSyncross (37).jpeg',
        texts: [
            "Business partners",
            "Units of measure groups",
            "Items",
            "User-defined tables",
            "Item groups",
            "Dimensions and projects replication",
            "Units of measure",
        ],
        feature: [],
        btntext: '',
        path: '/service-details'
    },
    {
        id: 'tab2',
        title: 'Consolidations, Eliminations & Reporting',
        subtitle: '',
        icon: 'images/icon/sr-icon-3-2.png',
        image: 'images/esyncross/eSyncross (52).jpeg',
        texts: [
            "Multi-level GL consolidation",
            "Hierarchical GL consolidation",
            "Chart of accounts replication",
            "User-defined tables",
            "Cost centre replication",
            "Intercompany transaction elimination",
            "Consolidated company reporting at the transaction level",
        ],
        feature: [],
        btntext: '',
        path: '/service-details'
    },
    {
        id: 'tab3',
        title: 'Inter- and Intra-Company Transactions',
        subtitle: '',
        icon: 'images/icon/sr-icon-3-3.png',
        image: 'images/esyncross/eSyncross (33).jpeg',
        texts: [
            "Purchase order > Sales order",
            "Delivery > Goods receipt purchase order",
            "A/R invoice > A/P invoice",
            "A/P invoice > A/R invoice",
            "Journal entries",
        ],
        feature: [],
        btntext: '',
        path: '/service-details'
    },
]

const SapBusinessOneInterCompany = () => (
    <Fragment>
        <Seo title="InterCompany for SAP Business One"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="InterCompany for SAP Business One"
                    pageSubTitle={"WORK SMARTER"}
                    pageDesc={"Streamline accounting with eSyncross’s automated reconciliation, consolidations, and reports."}
                    bgImage="images/bg/bg-sap.png"/>

        <SAPB1Video
            video={"/videos/SAPBusinessOneIntercompany.mp4"}
            title={"Automate Multi-Entity Accounting and Reporting"}
            subTitle={"ABOUT INTERCOMPANY FOR SAP BUSINESS ONE"}
            secTitle={"InterCompany for SAP Business One"}
            description={"Multi-entity accounting and reporting can be time-consuming and complicated, especially if you manually manage transactions and month-end close.\n" +
                "\n" +
                "InterCompany for SAP Business One simplifies and optimizes financial operations, prevents mistakes, and accelerates month-end close. Powered by Saltbox and available only from eSyncross."}
            className="space space-extra-bottom"/>

        <AboutEight
            data={data}
            listStyle={'2'}
            title={"InterCompany for SAP Business One"}
            subtitle={"Key Features"}
            className="space-top space-extra-bottom"/>
        <AboutFourCopy data={aboutFourData} className=""/>
        {aboutSevenData?.map((item,index)  => <AboutSevenCopy
            index={index}
            flip={item.flip}
            subTitle={item.subtitle}
            title={item.title}
            imgPath={item.imgPath}
            bottomList={item.bottomList}
            description={item.description}
        />)}

        <FooterOne/>
        <ScrollTopBtn/>
    </Fragment>
);

export default SapBusinessOneInterCompany;