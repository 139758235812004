import React from 'react';
import {ImageBoxFour, InfoMedia, SecSubTitle, SecTitle} from '../../components';

const AboutFourCopy = ({data,className})=> (
  <div className={className}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 mb-30 pt-10 pt-lg-0 text-center text-md-start">
          <SecSubTitle className={"text-center"}><i className="fas fa-bring-forward"/>{data.subtitle}</SecSubTitle>
          <SecTitle className="h1 mb-3 pb-3 normal-title text-center">{data.title}</SecTitle>
        </div>
      </div>
    </div>
  </div>
);

export default AboutFourCopy;