import React from 'react';

const InfoMedia = ({number, image, icon, title, info, className, classNameIcon,styleIcon ,styleFont}) => (
  <div className={`info-media ${className || ''}`}>
    <div style={styleIcon || {}} className={`media-icon ${classNameIcon || ''}`}>
      {image && <img src={image} alt="infoIcon"/>}
      {icon && <i className={icon}/>}
      {number && number}      
    </div>
    <div className="media-body">
      {title && <span style={styleFont || {}}  className="media-label">{title}</span>}
      {info && <div style={{whiteSpace:'pre-wrap'}} className="media-link">{info}</div>}
    </div>
  </div>
);

export default InfoMedia;
