import React, {useEffect, useState} from 'react';
import {CategoryWidget, GalleryWidget, RecentPostWidget, SearchWidget, TagWidget, Widget} from '../../components';

// Widgets Data
import widgetsData from '../../data/widgets.json';
import blogdata from '../../data/blog.json';
import {Link} from "react-router-dom";

const {recentPosts, blogTags, galleryImages, allServices} = widgetsData;

const SidebarOne = ({isDetails=true,className, handleSearch, handleTag, handelCat, searchText, selectedCat}) => {

    return (
        <aside className={`sidebar-area ${className || ''}`}>
            {!isDetails && <Widget>
                <SearchWidget searchText={searchText} handleSearch={handleSearch}/>
            </Widget>}
            {!isDetails && <Widget widgetTitle="Popular Tags" className="widget_tag_cloud">
                <TagWidget className={'d-flex flex-wrap'}>
                    {blogTags.map((tag, index) => (
                        <div onClick={() => handleTag(tag)}>
                            <TagWidget.Item key={index}>{tag}</TagWidget.Item>
                        </div>
                    ))}
                </TagWidget>
            </Widget>}
            <Widget widgetTitle="Recent Posts">
                {blogdata.slice(-3).map(post => (
                    <RecentPostWidget key={post.id}
                                      title={post.title}
                                      image={post.images[0]}
                                      date={post.date}
                                      path={`/blog-details/${post.id}`}
                    />
                ))}
            </Widget>
            {!isDetails && <Widget widgetTitle="Categories" className="widget_nav_menu">
                <CategoryWidget>
                    {allServices.map(cat => (
                        <div onClick={() => {
                            handelCat(cat)
                        }}>
                            <CategoryWidget.Item selected={selectedCat === cat} key={cat}
                                                 path="/blog">{cat}</CategoryWidget.Item>
                        </div>
                    ))}
                </CategoryWidget>
            </Widget>}
            <Widget widgetTitle="Gallery Posts">
                <GalleryWidget>
                    {blogdata.flatMap(blog =>
                        blog.images.map(image => ({id: blog.id, image}))
                    ).map((item, index) => (
                        <Link to={`/blog-details/${item.id}`}>
                            <GalleryWidget.Item key={index} src={item.image} alt="thumb"/>
                        </Link>
                    ))}
                </GalleryWidget>
            </Widget>

        </aside>
    )
};

export default SidebarOne;