import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {
        AboutFive,
        FeatureTwo,
        FooterOne,
        HeaderOneCopy,
        ProcessTwo, TestimonialTwo
} from '../containers';
import ProcessFour from "../containers/Process/ProcessFour";
import AboutSix from "../containers/Abouts/AboutSix";


const features = [
        {
                title: "Business Intelligence Tools",
                image: "images/icon/process-2-1.png",
                text: "Business Intelligence tools turn data into actionable insights through dashboards and visualizations."
        },
        {
                title: "Reports & Analytics",
                image: "images/icon/sr-icon-3-6.png",
                text: "Reports & Analytics transform data into comprehensive insights through detailed reports and visualizations."
        },
        {
                title: "Predictive Modeling & Forecasting",
                image: "images/icon/fe-2-4.png",
                text: "Predictive Modeling & Forecasting use historical data to predict future trends and outcomes."
        },
]

const data = [
        {
                id: 'tab1',
                title: 'Financial Analytics',
                subtitle: '259 Jobs Done',
                icon: 'images/icon/sr-icon-3-1.png',
                image: 'images/esyncross/eSyncross (30).jpeg',
                text: 'Financial analytics involves analyzing financial data to assess performance, profitability, and financial health. It helps in budgeting, forecasting, and identifying trends to support strategic decision-making.',
                feature: ["Budgeting Optimization" , "Forecasting Accuracy" , "Profitability Analysis" , "Risk Management"],
                btntext: 'Learn More',
                path: '/service-details'
        },
        {
                id: 'tab2',
                title: 'Sales Analytics',
                subtitle: '156 Jobs Done',
                icon: 'images/icon/sr-icon-3-2.png',
                image: 'images/esyncross/eSyncross (38).jpeg',
                text: 'Sales analytics examines sales data to understand customer behavior, optimize sales strategies, and improve revenue performance. It involves analyzing sales trends, customer segmentation, and sales channel effectiveness.',
                feature: ["Sales Performance" , "Customer Insights" , "Pipeline Analysis" , "Pricing Strategy"],
                btntext: 'Learn More',
                path: '/service-details'
        },
        {
                id: 'tab3',
                title: 'Purchase Analytics',
                subtitle: '592 Jobs Done',
                icon: 'images/icon/sr-icon-3-3.png',
                image: 'images/esyncross/eSyncross (52).jpeg',
                text: 'Purchase analytics analyzes procurement data to improve sourcing decisions, streamline supply chain processes, and optimize purchasing costs and supplier performance.',
                feature: ["Cost Reduction" , "Supplier Evaluation" , "Inventory Optimization" , "Procurement Insights"],
                btntext: 'Learn More',
                path: '/service-details'
        },
        {
                id: 'tab4',
                title: 'Administration Analytics',
                subtitle: '1259 Jobs Done',
                icon: 'images/icon/sr-icon-3-4.png',
                image: 'images/esyncross/eSyncross (54).jpeg',
                text: 'Administration analytics analyzes operational data from HR, IT, and facilities to enhance efficiency and decision-making, optimizing resource allocation and organizational effectiveness.',
                feature: ["Resource Allocation" , "Operational Efficiency" , "Compliance Monitoring" , "Process Improvement"],
                btntext: 'Learn More',
                path: '/service-details'
        },
]

const list = ["Extend SAP business One Limit","Save efforts and time","control and manage human errors and flows","level up your system",]

const SapB1BusinessAnalytics = () => (
    <Fragment>
        <Seo title="SAP Business One Analytics"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="Business Analytics"
                    pageSubTitle={"Level up SAP Business One"}
                    pageDesc={"SAP Business One analytics offers real-time insights for SMEs' data-driven decisions."}
                    bgImage="images/bg/bg-sap.png"/>


            <FeatureTwo features={features} title={"What Types of Business Analytics We Offer"} subTitle={"BEYOND BUSINESS"} className="space-top" featuresHeightClass={"ht-425"}/>

            <AboutSix
                data={data}
                title={"WHAT WE CAN ENHANCE YOUR SYSTEM"}
                subtitle={"AREAS OF BUSINESS"}
                className="space-top space-extra-bottom"/>



            <AboutFive title={"Connecting System and Building Bridges To the Next level"}
                       subTitle={"Automation is a must"}
                       description={"Involve data migration, system configuration, custom development, and user training. These tasks ensure seamless connectivity with other applications, enhancing overall business efficiency and performance."}
                       number={"150+"}
                       images={[
                               'images/esyncross/eSyncross (40).jpeg',
                               'images/esyncross/eSyncross (46).jpeg',
                               'images/esyncross/eSyncross (41).jpeg',
                       ]}
                       mediaTitle={"Integrations tasks and activities"}
                       bottomList={list}
                       buttonPath={"/about"}
                       className="space-top space-extra-bottom"/>

        <FooterOne/>
        <ScrollTopBtn/>
    </Fragment>
);

export default SapB1BusinessAnalytics;