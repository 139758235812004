import React from 'react';
import {Button} from '../../components';

const SoonOne = ()=> (
  <div className="space">
    <div className="container">
      <div className="error-content text-center">
        <h3 className="error-title">Coming Soon</h3>
        <p className="error-text">This Service will be available soon</p>
        {/*<Button path="/"><i className="fas fa-home ms-0 me-2"/>Back To Home</Button>*/}
      </div>
    </div>
  </div>
);

export default SoonOne;