import React, {useEffect, useState} from 'react';

const SearchWidget = ({handleSearch,searchText})=> {
  // STATES
    const [search, setSearch] = useState('');

    useEffect(()=>{
        setSearch(searchText)
    },[searchText])
    // HANDLER
    const searchHandler = (e) => {
      setSearch(e.target.value);
    };

  
  
  return (
    <div className="search-form">
      <input value={search} onChange={searchHandler} type="text" placeholder={'Search Here'}/>
      <button onClick={()=>handleSearch(search)} ><i className="far fa-search"></i></button>
    </div>
  )
};

export default SearchWidget;