import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {BlogFour, CtaOne, FooterOne, HeaderOneCopy, PricePlanTwo} from '../containers';

const PricePlan = ()=> (
  <Fragment>
    <Seo title="Pricing Plans" />
    <HeaderOneCopy />
    <Breadcrumb
        pageName="Pricing Plans"
        pageSubTitle={"Choose the Right Plan for Your Needs"}
        pageDesc={"Here you can find different pricing options tailored to their specific requirements, encouraging them to explore the details further. Adjust as necessary to match the specific offerings and structure of your price plans."}
        bgImage="images/breadcumb/breadcumb-bg.jpg" />
    <PricePlanTwo className="space-top space-extra-bottom"/>
    <CtaOne className="space"/>
    <BlogFour className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default PricePlan;