import React, {useRef, useState} from 'react';
import { Button } from '../';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import {PUBLIC_KEY, SERVICE_ID, TEMPLATE_ID} from "./FormFive";
import Loader from "../Loader/Loader";

const FormOne = ({ className, title, btnText }) => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        subject: '',
        // date: new Date().toLocaleDateString('en-ca', {
        //     year: "numeric",
        //     month: "numeric",
        //     day: "numeric"
        // }),
        message: '',
    });
    const dateInputRef = useRef(null);
    const [showValidation, setShowValidation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    console.log(formData)
    const handleDivClick = () => {
        if (dateInputRef.current) {
            dateInputRef.current.focus();
            dateInputRef.current.showPicker(); // This is a modern way to trigger the date picker
        }
    };
    const isValidName = formData.fullName.trim() !== '';
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
    const isValidMessage = formData.message.trim() !== '';

    const sendEmail = async (event) => {
        event.preventDefault();

        if (!isValidName || !isValidEmail || !isValidMessage) {
            toast.warn(isValidName && isValidMessage && (formData.email !== '') ?
                'Please type a valid email.' :
                'Please fill all fields first.');
            setShowValidation(true);
            return;
        }

        setShowValidation(false);

        const templateParams = {
            to_email: 'sales@esyncross.com', // Replace with actual recipient email
            name: formData.fullName,
            email: formData.email,
            service: formData.subject,
            // date: formData.date,
            message: formData.message,
        };

        setIsLoading(true);
        emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, PUBLIC_KEY)
            .then((response) => {
                setFormData({
                    fullName: '',
                    email: '',
                    subject: '',
                    // date: '',
                    message: '',
                });
                setIsLoading(false);
                toast.success('Email sent successfully');
            }, () => {
                setIsLoading(false);
                toast.error('Failed to send email');
            });
    };

    return (
        <>

            <form className={`form-style1 ${className || ''}`} onSubmit={sendEmail}>
                <h2 className="form-title h4" style={{color: "var(--yellow-color)"}}>{title}</h2>
                <div className="form-group">
                    <input
                        type="text"
                        name="fullName"
                        placeholder="Enter Full Name"
                        value={formData.fullName}
                        onChange={handleChange}
                        className={showValidation && !isValidName ? 'invalid' : ''}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                        className={showValidation && !isValidEmail ? 'invalid' : ''}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        name="subject"
                        placeholder="Appointment Subject"
                        value={formData.subject}
                        onChange={handleChange}
                    />
                </div>
                {/*<div className="form-group" onClick={handleDivClick}>*/}
                {/*    <input*/}
                {/*        type="date"*/}
                {/*        name="date"*/}
                {/*        className={"cursor-pointer"}*/}
                {/*        placeholder="Appointment Date"*/}
                {/*        value={formData.date}*/}
                {/*        onChange={handleChange}*/}
                {/*        min={new Date().toLocaleDateString('en-ca', {*/}
                {/*            year: "numeric",*/}
                {/*            month: "numeric",*/}
                {/*            day: "numeric"*/}
                {/*        })}*/}
                {/*        ref={dateInputRef}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="form-group">
        <textarea
            name="message"
            placeholder="Write a short message..."
            value={formData.message}
            onChange={handleChange}
            className={showValidation && !isValidMessage ? 'invalid' : ''}
        />
                </div>
                <div className="form-btn">
                    <Button  className="style7" type="submit" disabled={isLoading}>
                        {btnText}
                        <i className="far fa-arrow-right" />
                    </Button>
                </div>
            </form>

            {isLoading && <Loader/>}
        </>
    );
};

export default FormOne;
