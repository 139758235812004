import React from 'react';
import {FormOne, InfoMedia, SecSubTitle, SecTitle} from '../../components';
import {Link} from "react-router-dom";

const ContactOne = ()=> (
  <div className="background-image" style={{backgroundImage: 'url(images/bg/appoin-bg-1-1.jpg)'}}>
    <div className="container z-index-common">
      <div className="row gx-60">
        <div className="col-xl-6 align-self-center space">
          <SecSubTitle className="">Make An Appointment</SecSubTitle>
          <SecTitle className="text-capitalize h1 text-white">We Provide Best Advices For Your Future</SecTitle>
          <hr className="hr-style1"/>
          <p className="mb-4 mt-1 pb-3 text-white">
            We seamlessly integrate cross-platform solutions using our top-tier expertise to drive innovative initiatives.
            We are committed to delivering high-quality results by leveraging cutting-edge technologies and methodologies.
          </p>
          <div className="row gy-30">
            <div className="col-md-6">

              <InfoMedia className="contact-media"
                icon="fal fa-phone-volume"
                title="CALL AVAILABLE"
                info={<a href="tel:+971585703053">+971 585703053</a>}
              />
            </div>
            <div className="col-md-6">
              <InfoMedia className="contact-media"
                icon="fal fa-envelope"
                title="Write a Quick mail"
                info={<a href="mailto:sales@esyncross.com">sales@esyncross.com</a>}
              />
            </div>
            <div className="col-md-12">
              <Link className="info-media" target={'_blank'} to={'https://www.google.com/maps/place/eSyncross+LLC/@24.9881639,55.1931504,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f736da1e2d37f:0xbe309355aa4845e1!8m2!3d24.9881639!4d55.1931504!16s%2Fg%2F11w24m5l_r?entry=ttu'}>
              <InfoMedia  className="contact-media"
                  icon="fal fa-map-marker-alt"
                  title="Office Address"
                  info={'WH 4&5, Plot No. 597 674 Dubai - UAE'}
              />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-6 form-wrap1">
          <FormOne
            title="Make An Appointment"
            btnText="Make An Appointment"
          />
        </div>
      </div>
    </div>
  </div>
);


export default ContactOne;