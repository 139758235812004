import React from 'react';
import privacy from "../../data/privacy.json";

const PrivacyTemplate = ()=> (
  <div className="space">
    <div className="container">
      <div className="error-content">
        <h3 className="">Privacy Policy</h3>
          {
              privacy.map((obj, index) => <div className={'space-bottom'}>
                  <h5 className={"title"}>{index + 1} - {obj.mainTitle}</h5>
                  <div>{obj.answer}</div>
                  {obj.details.map(detail => <>
                      <div><h6 style={{margin: 0}}>{detail.title}:</h6> {detail.desc}</div>
                  </>)}
              </div>)
          }
      </div>
    </div>
  </div>
);

export default PrivacyTemplate;