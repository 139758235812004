import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {CareerSingle, FooterOne, HeaderOneCopy} from '../containers';
import {useParams} from "react-router-dom";

const CareerDetails = () => {

    const {id} = useParams();

    return (
        <Fragment>
            <Seo title="Career Details"/>
            <HeaderOneCopy/>
            <Breadcrumb
                pageName="Career Details"
                pageSubTitle={"Discover Your Future with eSyncross"}
                pageDesc={"Explore available positions, responsibilities, benefits, and our culture at eSyncross."}
                bgImage="/images/bg/bg-image-3.png"/>
            <CareerSingle id={id} className={"space-top space-extra-bottom"}/>
            <FooterOne/>
            <ScrollTopBtn/>
        </Fragment>
    )
};

export default CareerDetails;