import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {FooterOne, HeaderOneCopy, ProjectSingle} from '../containers';

const ProjectDetails = ()=> (
  <Fragment>
    <Seo title="Project Details" />
    <HeaderOneCopy />
    <Breadcrumb
        pageName="Project Details"
        pageSubTitle={"Explore the Vision and Execution"}
        pageDesc={"Introduces a detailed look at the project's vision and execution, inviting exploration of its specifics and achievements."}
        bgImage="images/bg/bg-image-1.png" />
    <ProjectSingle className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default ProjectDetails;