import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {
        AboutFive,
        AboutFour,
        AboutThree, BrandTwo,
        ContactTwo,
        FeatureTwo,
        FooterOne,
        HeaderOneCopy,
        ProcessTwo, TestimonialTwo
} from '../containers';
import productsData from '../data/products.json';


const features = [
        {
                title: "Customized solutions",
                image: "images/icon/fe-2-1.png",
                text: "Work hand-in-hand with our team to outline and perfect your app requirements, ensuring it meets your business goals precisely."
        },

        {
                title: "Flexibility and Scalability",
                image: "images/icon/fe-2-2.png",
                text: "Our agile approach adapts quickly to your evolving needs, ensuring scalability and future-proofing."
        },
        {
                title: "Seamless Integration",
                image: "images/icon/fe-2-3.png",
                text: "Expert SAP B1 integration ensures your app syncs with your ERP system, providing real-time data and boosting efficiency."
        },
]

const processData = [
        {
                "number": "01",
                "arrow": "images/icon/process-arrow-1-1.png",
                "arrow2": "images/icon/process-arrow-2-1.png",
                "icon": "images/icon/process-1-4.png",
                "title": "Initial Consultation",
                "text": "Discuss and gather your specific business needs."
        },
        {
                "number": "02",
                "arrow": "images/icon/process-arrow-1-1.png",
                "arrow2": "images/icon/process-arrow-2-1.png",
                "icon": "images/icon/process-1-1.png",
                "title": "Development",
                "text": "Create and customize the app for seamless SAP B1 integration."
        },
        {
                "number": "03",
                "arrow": "images/icon/process-arrow-1-1.png",
                "arrow2": "images/icon/process-arrow-2-1.png",
                "icon": "images/icon/process-1-2.png",
                "title": "Testing and Launch",
                "text": "Conduct thorough testing to ensure performance and reliability."
        },
        {
                "number": "04",
                "arrow": "images/icon/process-arrow-1-1.png",
                "arrow2": "images/icon/process-arrow-2-1.png",
                "icon": "images/icon/process-1-3.png",
                "title": "Support",
                "text": "Provide ongoing support, updates, and maintenance."
        }
]

const aboutFourData = {
        title:'Why Choose Us?',
        subtitle:'TRUSTED SERVICE FROM A TRUSTED PARTNER ',
        images:[
            'images/esyncross/eSyncross (25).jpeg',
            'images/esyncross/eSyncross (22).jpeg',
        ],
        points: [
                {
                        title: "Customization Expertise",
                        text: "We bring extensive experience in importing and customizing applications tailored to diverse industries and business processes. We bring extensive experience in importing and customizing applications tailored to diverse industries and business processes."
                },
                {
                        title: "Quality Commitment",
                        text: "Our focus is on delivering high-quality solutions that streamline operations, cut costs, and boost productivity. Our focus is on delivering high-quality solutions that streamline operations, cut costs, and boost productivity."
                },
                {
                        title: "Industry Insights",
                        text: "Leveraging deep industry insights to ensure our solutions meet specific business needs effectively. Leveraging deep industry insights to ensure our solutions meet specific business needs effectively."
                }
        ]


}

const MobileApps = () => (
    <Fragment>
        <Seo title="Mobile Applications"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="Mobile Applications"
                    pageSubTitle={"EXPAND YOUR MOBILE REACH"}
                    pageDesc={"Streamline processes with our innovative mobile apps for efficiency and on-the-go access."}
                    bgImage="images/bg/bg-image-2.png"/>



            <FeatureTwo features={features} title={"Our Development Services"} subTitle={"Expert Developers"} className="space-top mb-50" featuresHeightClass={"ht-425"}/>
            <AboutFour data={aboutFourData} className="space space-top space-bottom mt-5"/>


            <ProcessTwo data={processData} className="space-top space-extra-bottom"/>

        <FooterOne/>

        <ScrollTopBtn/>
    </Fragment>
);

export default MobileApps;