import React, {useRef, useState} from 'react';
import {Button, InfoMedia} from '../../components';
import careerdata from '../../data/career.json';
import {Link} from "react-router-dom";

const CareerSingle = ({className,id})=> {

  const contactArea = useRef(null);

  const [career, setCareer] = useState(careerdata?.find(career => career?.id === parseInt(id)));



  const scrollHandler = (event)=> {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      top: targetOffset,
      behavior: "smooth"
    });
  }


  const title = "Applying for "+ career?.title
  const message =encodeURIComponent(`Dear eSyncross Team,
Hope all is well.\n
I came across a career opportunity on your website for the position of "${career?.title}" and I am very interested. Please find my attached CV for your review.\n
Best regards,\n
`);



  return (
    <div className={className}>
      <div className="container">
        <div className="row gx-60 gy-2">
          <div className="col-lg-5 mb-30">
            <img src={career?.image} alt="img"/>
          </div>
          <div className="col-lg-7 col-xl-7 align-self-center mb-30">
            <div className="team-about">
              <h3 className="team-name h4">{career?.title}</h3>
              {/*<span className="team-degi">Founder & CEO</span>*/}
              <p className="team-text">{career?.text}</p>
              <div className="row">
                {
                  career?.requirements?.map(req => {
                    return (
                        <div className="col-sm-6">
                          <InfoMedia className="team-media"
                                     icon="fas fa-bullseye-arrow"
                                     title={req?.title}
                                     info={req?.description}
                          />
                        </div>
                    )
                  })
                }

              </div>
              <div className="onepage-nav">
                <Link
                    to={"mailto:sales@esyncross.com?subject="+title+"&body="+message}
                ><Button>
                  Apply Now<i className="far fa-arrow-right"/>
                </Button>
                </Link>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default CareerSingle;