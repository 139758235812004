import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {FooterOne, HeaderOneCopy, NotFoundOne} from '../containers';
import SoonOne from "../containers/Soon/SoonOne";

const Soon = ({title, pageDesc, pageSubTitle})=> (
  <Fragment>
    <Seo title="Soon Page" />
    <HeaderOneCopy />
    <Breadcrumb pageName={title} pageSubTitle={pageSubTitle} pageDesc={pageDesc} bgImage="images/bg/bg-image-5.png" />
    <SoonOne />
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default Soon;