import React from 'react';
import {Link} from 'react-router-dom';
import {List} from "../index";

const ServiceBoxThreeDynamic = ({points, icon, title,text})=> (
  <div className="service-style3">
    <div className="service-front">
      <div className="service-content SelectedAboutEight">
          <h3 className="service-title h5 " style={{height:100,textAlign:'center',color:'white',padding:20}}>{title}</h3>
      </div>
      <div className="service-content ">
        <div className="service-icon"><img src={icon} alt="icon"/></div>
          <List className="list-styleBV" >
              {points.map(o => <List.Item><i className="far fa-check-circle"/> {o}</List.Item>)}
          </List>
          {/*{
              points?.map(item=>{
                  return (
                      <div className={"BVwidget mb-2"}>
                          {item}
                      </div>
                  )
              })
          }*/}
      </div>
    </div>

    <div className="service-back" >
      <div  style={{backgroundImage:"url(images/bg/sr-box-bg-1.jpg)",objectFit:'contain',height:400}} className="service-content">
        <div className="service-icon"><img src={icon} alt="icon"/></div>
          <h3 className="service-title h6">{title}</h3>
        <p className="service-text">{text}</p>
      </div>
    </div>
  </div>
);

export default ServiceBoxThreeDynamic;