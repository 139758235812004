import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {FooterOne, HeaderOneCopy} from '../containers';
import Career from "../containers/Careers/CareerOne";

const Careers = () => (
  <Fragment>
    <Seo title="Careers" />
    <HeaderOneCopy />
    <Breadcrumb
        pageName="Career List"
        pageSubTitle={"Discover Your Future with eSyncross"}
        pageDesc={"Discover fulfilling career opportunities at eSyncross. Join us for professional growth."}
        bgImage="/images/bg/bg-image-3.png" />
    <Career className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default Careers;