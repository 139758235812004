import React from 'react';
import Slider from 'react-slick';
import {Button} from '../../components';

const HeroOne = ()=> {   

  const HEROCONTENT = [
    {
      image: 'images/hero/hero-1-1.jpg',
      title: <>Empowering <span className="hero-innertext">Innovative Technology Solutions</span> <br/>Businesses with Elemental Solutions
      </>,
      subtitle:<span className="hero-innertext-sm">Innovative Technology Solutions</span>,
      text: 'Leverage our expertise in IT solutions and business consulting to transform your operations. We deliver tailored solutions, expert consulting, and comprehensive system integrations.',
    },
    {
      image: 'images/hero/hero-1-2.jpg',
      title: <>Essential <span className="hero-innertext">Transforming Visions into Reality</span> <br/>Solutions from a Trusted Partner</>,
      subtitle:<span className="hero-innertext-sm">Transforming Visions into Reality</span>,
      text: 'At eSyncross, we turn your business visions into reality with our innovative technology solutions and expert consulting services.',
    },
    {
      image: 'images/hero/hero-1-3.jpg',
      title: <>Innovative <span className="hero-innertext">Stay ahead in the digital age</span> <br/>Solutions for a Digital World</>,
      subtitle:<span className="hero-innertext-sm">Stay ahead in the digital age</span>,
      text: 'Stay ahead in the digital age with eSyncross. We provide state-of-the-art IT solutions and strategic business consulting to help you navigate the complexities of modern business environments.',
    }
  ];
  
  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          arrows: false,
        }
      }
    ]
  };
  
  
  return (
    <Slider className="hero-layout1" {...settings}>
      {HEROCONTENT.map((slide, index) => (
        <div key={index}>
          <div className="hero-inner">
            <div className="hero-shape1"></div>
            <div className="hero-shape2"></div>
            <div className="hero-bg background-image" style={{backgroundImage: `url(${slide.image})`}}></div>
            <div className="container">
              <div className="hero-content">
                {slide.subtitle}
                <h1 className="hero-title">{slide.title}</h1>
                <p className="hero-text">{slide.text}</p>
                <div className="hero-btns">
                  <Button
                      // style={{boxShadow:'0 0 100px 50px #2B61A955'}}
                      path={'/services'}>Services<i className="far fa-arrow-right"/></Button>
                  <Button path={'/products'} className="style2">Solutions<i className="far fa-arrow-right"/></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}    
    </Slider>
  );
}

export default HeroOne; 