import React from 'react';
import {Link} from 'react-router-dom';
import {SecSubTitle} from "../Titles/Titles";

const Breadcrumb = ({pageName, bgImage, pageSubTitle, pageDesc}) => (
  <div className="breadcumb-wrapper background-image" style={{backgroundImage: `url('${bgImage}')`}}>
      <div className="container z-index-common">
          <div className="breadcumb-content">
             <div>
                 {pageSubTitle && <SecSubTitle className={"breadcumb_headline"}>{pageSubTitle}</SecSubTitle>}
                 <h1 className="breadcumb-title">{pageName}</h1>
                 {pageDesc && <div className="breadcumb_texts_description">{pageDesc}</div>}
             </div>
          </div>
      </div>
  </div>
);

export default Breadcrumb;