import React from 'react';

const GalleryWidget = ({children})=> (
  <div style={{height:250,overflowY:'auto'}}  className="sidebar-gallery">{children}</div>
);

GalleryWidget.Item = ({src, alt})=> (
  <div className="gallery-thumb">
    <img src={src} alt={alt} style={{height:60,objectFit:'cover'}} className="w-100"/>
  </div>
);

export default GalleryWidget;