import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {
        AboutFive,
        AboutFour,
        AboutThree,
        ContactTwo,
        FeatureTwo,
        FooterOne,
        HeaderOneCopy,
        ProcessTwo, TestimonialTwo
} from '../containers';
import integrationTabs from '../data/integrationTabs.json';


const features = [
        {
                title: "SAP B1 Components (Automation)",
                image: "images/icon/fe-2-1.png",
                text: "Integrates complex scenarios between SAP B1 companies, automates workflows, and more"
        },
        {
                title: "Third Party Systems",
                image: "images/icon/fe-2-2.png",
                text: "Any system or software that completes a process or a workflow, like WMS, HR, Banks, Government, and more"
        },
        {
                title: "Hardware Devices",
                image: "images/icon/fe-2-3.png",
                text: "Machines, devices, that stores data into SAP B1."
        },
]
const list = ["Extend SAP business One Limit","Save efforts and time","control and manage human errors and flows","level up your system",]
const SapB1Integration = () => (
    <Fragment>
        <Seo title="SAP Business One Integration"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="SAP Business One Integration"
                    pageSubTitle={"EXTEND SAP BUSINESS ONE REACH"}
                    pageDesc={"Unifies operations, boosts efficiency, improves data accuracy, and supports decision-making."}
                    bgImage="images/bg/bg-sap.png"/>


        <AboutFive title={"Connecting System and Building Bridges To the Next level"}
                   subTitle={"Automation is a must"}
                   description={"Involve data migration, system configuration, custom development, and user training. These tasks ensure seamless connectivity with other applications, enhancing overall business efficiency and performance."}
                   number={"150+"}
                   mediaTitle={"Integrations tasks and activities"}
                   bottomList={list}
                   images={[
                       'images/esyncross/eSyncross (32).jpeg',
                       'images/esyncross/eSyncross (46).jpeg',
                       'images/esyncross/eSyncross (36).jpeg',
                   ]}
                   buttonPath={"/about"}
                   className="space-top space-extra-bottom"/>


            <FeatureTwo features={features} title={"What Our Team Integrates"} subTitle={"TRUSTED DEVELOPERS"} className="space-top" featuresHeightClass={"ht-425"}/>

            <ProcessTwo className="space-top space-extra-bottom"/>

            <TestimonialTwo data={integrationTabs} className="space-top space-extra-bottom"/>
        <FooterOne/>
        <ScrollTopBtn/>
    </Fragment>
);

export default SapB1Integration;