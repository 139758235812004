import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {
        AboutFive,
        AboutFour,
        AboutThree, BrandTwo,
        ContactTwo,
        FeatureTwo,
        FooterOne,
        HeaderOneCopy,
        ProcessTwo, TestimonialTwo
} from '../containers';
import productsData from '../data/products.json';


const features = [
        {
                title: "Seamless Transition",
                image: "images/icon/process-2-3.png",
                text: "Our cloud migration services ensure a smooth and hassle-free transition, minimizing downtime and disruption to your business operations."
        },

        {
                title: "Expert Planning",
                image: "images/icon/fe-1-2.png",
                text: "We provide detailed planning and execution strategies to ensure your migration is efficient, effective, and tailored to your specific needs."
        },
        {
                title: "Ongoing Support",
                image: "images/icon/ab-ic-2-2.png",
                text: "Post-migration, our team offers continuous support to help you optimize your new cloud environment and address any challenges that arise."
        },
]


const aboutFourData = {
        title:'Make Our Cloud Migration Services Your Solution',
        subtitle:'TRUSTED SERVICE FROM A TRUSTED PARTNER',
        images:[
                '/images/esyncross/eSyncross (7).jpeg',
                '/images/esyncross/eSyncross (5).jpeg',
        ],
        points: [
                {
                        title: "Smooth and Efficient Transition",
                        text: "Our expert team ensures a seamless migration process with minimal disruption to your business operations, enabling you to quickly benefit from the advantages of the cloud."
                },
                {
                        title: "Tailored Migration Strategies",
                        text: "We develop customized migration plans that align with your specific business needs, ensuring an optimized and effective transition to the cloud."
                },
                {
                        title: " Continuous Post-Migration Support",
                        text: "Our commitment doesn't end with migration. We provide ongoing support and optimization to ensure your cloud environment remains efficient and effective, supporting your long-term business goals."
                }
        ]


}

const CloudMigration = () => (
    <Fragment>
        <Seo title="Cloud Migration"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="Cloud Migration"
                    pageSubTitle={"Effortless Migration for Optimal Performance"}
                    pageDesc={"Experience a seamless cloud transition with our expert migration services."}
                    bgImage="images/bg/bg-cloud.png"/>



            <FeatureTwo features={features} title={"What Our Experts Bring To You"} subTitle={"Expert Developers"} className="space-top mb-50" featuresHeightClass={"ht-425"}/>
            <AboutFour data={aboutFourData} className="space space-top space-bottom mt-5"/>



        <FooterOne/>

        <ScrollTopBtn/>
    </Fragment>
);

export default CloudMigration;