import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import {FormFive, InfoMedia} from '../../components';
import {Link} from "react-router-dom";

const ContactTwo = ({ initialService, ...restProps}) => (
    <div {...restProps}>
        <div className="container">
            <div className="row">
                <div className="col-lg-6 mb-30">
                    <div className="contact-box">
                        <h3 className="contact-box__title h4">Dubai Office Address</h3>
                        <p className="contact-box__text">For all inquiries, support, and assistance, please contact our
                            office located in Dubai.</p>
                        <div className="info-media">
                            <InfoMedia
                                icon="fal fa-phone-alt"
                                title="Phone Number & Email"
                                info={<><a className={"custom-link"} href="tel:+971585703053">+971 585703053</a>
                                    <br/>
                                    <a className={"custom-link"} href="mailto:sales@esyncross.com">sales@esyncross.com</a></>}
                            />
                        </div>

                        <Link className="info-media" target={'_blank'} to={'https://www.google.com/maps/place/eSyncross+LLC/@24.9881639,55.1931504,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f736da1e2d37f:0xbe309355aa4845e1!8m2!3d24.9881639!4d55.1931504!16s%2Fg%2F11w24m5l_r?entry=ttu'}>
                            <InfoMedia
                                icon="far fa-map-marker-alt"
                                title="Our Office Address"
                                info={<p className={"mb-0 custom-link"}>
                                    {"WH 4&5, Plot No. 597 674\nDubai - UAE"}</p>}
                            />
                        </Link>
                        <InfoMedia
                            icon="fal fa-ticket-alt"
                            title="Support Services"
                            info={<>
                                <a className={"custom-link"} target={'_blank'} href="https://esyncross.freshdesk.com/support/tickets/new">Login to support portal</a>
                                <br/>
                                <a className={"custom-link"} target={'_blank'}  href="https://esyncross.freshdesk.com/support/login">Submit new ticket</a>
                            </>}
                        />
                    </div>
                </div>
                <div className="col-lg-6 mb-30">
                    <div className="contact-box">
                        <h3 className="contact-box__title h4">Leave a Message</h3>
                        <p className="contact-box__text">We’re Ready To Help You</p>
                        <FormFive initialService={initialService}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ContactTwo;