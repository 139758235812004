import React from 'react';
import {Link} from 'react-router-dom';
import Slider from 'react-slick';
import {Blog, SecSubTitle, SecTitle, TitleWrap} from '../../components/';

// blog Two data
import blogData from '../../data/blog.json';

const BlogTwo = ({className})=> {
  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {          
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {          
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {          
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className={`background-image ${className || ''}`} style={{backgroundImage: 'url(images/bg/blog-bg-1-1.jpg)'}}>   
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-xl-6">
            <TitleWrap>
              <SecSubTitle>Weekly Updates</SecSubTitle>
              <SecTitle className="h1">Latest Blog</SecTitle>
            </TitleWrap>
          </div>
        </div>
        <Slider className="row" {...settings}>
          {blogData.slice(0,5).map(post => (
            <Blog className="blog-style1" key={post.id}>
              <Blog.Image path={`/blog-details/${post.id}`} src={post.images[0]} />
              <Blog.Body>
                <Blog.Meta>
                  <Link to={`/blog-details/${post.id}`}><i className="far fa-calendar"/>{post.date}</Link>
                  <Link to={`/blog-details/${post.id}`}><i className="fal fa-user"/>{post.authorName}</Link>
                </Blog.Meta>
                <Blog.Title className="h5" path={`/blog-details/${post.id}`}>{post.title}</Blog.Title>
                <Link to={`/blog-details/${post.id}`} className="link-btn">Read Details <i className="far fa-arrow-right"></i></Link>
              </Blog.Body>
            </Blog>
            ))}
        </Slider>        
      </div>
    </div>
  );
}

export default BlogTwo;