import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {ContactTwo, FooterOne, HeaderOneCopy, PrivacyTemplate} from '../containers';

const Privacy = () => (
  <Fragment>
    <Seo title="Privacy Policy" />
    <HeaderOneCopy />
    <Breadcrumb pageName="Privacy Policy"
                pageSubTitle={"Data Protection"}
                pageDesc={"Last Update: 12/10/2023"}
                bgImage="images/bg/bg-image-6.png" />
    <PrivacyTemplate className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default Privacy;