import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {FooterOne, HeaderOneCopy, ProductSingle, ServicesNew} from '../containers';

const products =  [
    {
        title: 'All Solutions',
        link: '#',
        icon: 'fas fa-sync',
        subItems: [

            { objectFit: 'cover', reference: 'ERP Solutions',title: 'SAP Business One', link: '/sap-business-one', imgPath: '/images/blog/sap-b1.png', description: 'Designed for small to midsize businesses' },
            { objectFit: 'cover', reference: 'Automation for SAP Business One', title: 'Share files and reports by Email', link: '#', imgPath: '/images/blog/sapb1-reporting.jpg', description: 'Automatically sharing files and reports via email.' },
            { objectFit: 'contain', reference: 'Only From eSyncross', title: 'Warehouse Management System (EVO-WMS)', link: '/EVO-WMS',  imgPath: '/images/evo/logo.png',  description: 'A specialized solution for efficient warehouse operations' },
            { objectFit: 'cover', reference: 'ERP Solutions', title: 'Intercompany for SAP Business One', link: '/sap-business-one-inter-company',  imgPath: '/images/blog/sap-b1-intercompany.png', description: 'Multi-company transactions automation' },
            { objectFit: 'contain', reference: 'Only From eSyncross', title: 'BizVault', link: '/BizVault',  imgPath: '/images/bizvaultLogo.png',  description: 'A secure and centralized digital repository.' },
            { objectFit: 'cover', reference: 'Automation for SAP Business One', title: 'Time Attendance & Employees Expense', link: '#', imgPath: '/images/blog/sap-b1-time-attendance.jpeg', description: 'Integrated solution with HR Module' },
            { objectFit: 'cover', reference: 'Automation for SAP Business One', title: 'Online Purchase Quotation Update', link: '#', imgPath: '/images/blog/sap-b1-sales-quotation.jpg', description: 'Efficient updating of procurement quotes online.' },
            { objectFit: 'cover', reference: 'Only From eSyncross', title: 'Customs Addon', link: '/customs-addon',  imgPath: '/images/sapb1/sap-b1-addons.jpeg',  description: 'Bill of Entry & Exit & Customs Documents' },
            { objectFit: 'cover', reference: 'Automation for SAP Business One', title: 'Online Sale Order', link: '#', imgPath: '/images/blog/sap-b1-sales-order.webp', description: 'Efficient online sales order processing.' },
            { objectFit: 'cover', reference: 'Automation for SAP Business One', title: 'Payments Processing by bank Integration', link: '#', imgPath: '/images/blog/sap-b1-payment.jpg', description: 'Secure tool communicates with banks portal' }
        ]
    },
    {
        title: 'ERP Solutions',
        link: '#',
        icon: 'fas fa-building',
        subItems: [
            { objectFit: 'cover', reference: 'ERP Solutions',title: 'SAP Business One', link: '/sap-business-one', imgPath: '/images/blog/sap-b1.png', description: 'Designed for small to midsize businesses' },
            { objectFit: 'cover', reference: 'ERP Solutions', title: 'Intercompany for SAP Business One', link: '/sap-business-one-inter-company',  imgPath: '/images/blog/sap-b1-intercompany.png', description: 'Multi-company transactions automation' },
        ]
    },
    {
        title: 'Automation for SAP Business One',
        link: '#',
        icon: 'fas fa-map-marker-alt',
        subItems: [
            { objectFit: 'cover', reference: 'Automation for SAP Business One', title: 'Share files and reports by Email', link: '#', imgPath: '/images/blog/sapb1-reporting.jpg', description: 'Automatically sharing files and reports via email.' },
            { objectFit: 'cover', reference: 'Automation for SAP Business One', title: 'Time Attendance & Employees Expense', link: '#', imgPath: '/images/blog/sap-b1-time-attendance.jpeg', description: 'Integrated solution with HR Module' },
            { objectFit: 'cover', reference: 'Automation for SAP Business One', title: 'Online Purchase Quotation Update', link: '#', imgPath: '/images/blog/sap-b1-sales-quotation.jpg', description: 'Efficient updating of procurement quotes online.' },
            { objectFit: 'cover', reference: 'Automation for SAP Business One', title: 'Online Sale Order', link: '#', imgPath: '/images/blog/sap-b1-sales-order.webp', description: 'Efficient online sales order processing.' },
            { objectFit: 'cover', reference: 'Automation for SAP Business One', title: 'Payments Processing by bank Integration', link: '#', imgPath: '/images/blog/sap-b1-payment.jpg', description: 'Secure tool communicates with banks portal' },
        ]
    },
    {
        title: 'Only From eSyncross',
        link: '#',
        icon: 'fas fa-exchange-alt',
        subItems: [
            { objectFit: 'contain', reference: 'Only From eSyncross', title: 'Warehouse Management System (EVO-WMS)', link: '/EVO-WMS',  imgPath: '/images/evo/logo.png',  description: 'A specialized solution for efficient warehouse operations' },
            { objectFit: 'contain', reference: 'Only From eSyncross', title: 'BizVault', link: '/BizVault',  imgPath: '/images/bizvaultLogo.png',  description: 'A secure and centralized digital repository.' },
            { objectFit: 'cover', reference: 'Only From eSyncross', title: 'Customs Addon', link: '/customs-addon',  imgPath: '/images/sapb1/sap-b1-addons.jpeg',  description: 'Bill of Entry & Exit & Customs Documents' }
        ]
    }
]
const Products = ()=> (
  <Fragment>
    <Seo title="Solution Details" />
    <HeaderOneCopy />
    <Breadcrumb
        pageName="Solutions"
        pageSubTitle={"Explore Our Range of Innovative Solutions"}
        pageDesc={"Explore our innovative products, from cutting-edge solutions to essentials, designed to exceed your expectations."}
        bgImage="images/bg/bg-image-2.png" />

      <ServicesNew type={"Solutions"} data={products} className="space-top space-extra-bottom" />
  {/*  <ProductSingle className="space-top space-extra-bottom"/>*/}
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default Products;