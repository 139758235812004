import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {BlogSingle, FooterOne, HeaderOneCopy} from '../containers';
import {useParams} from "react-router-dom";

const BlogDetails = () => {
    const {id} = useParams();

    return (
        <Fragment>
            <Seo title="Blog Details"/>
            <HeaderOneCopy/>
            <Breadcrumb
                pageName="Blog Details"
                pageSubTitle={"Delve Deeper into Digital Transformation and ERP Insights"}
                pageDesc={"This subtitle introduces an in-depth exploration of digital transformation and ERP topics."}
                bgImage="/images/bg/bg-image-4.png"/>
            <BlogSingle id={id} className="space-top space-extra-bottom"/>
            <FooterOne/>
            <ScrollTopBtn/>
        </Fragment>
    )

};

export default BlogDetails;