import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from "react-bootstrap";

const Pagination = ({disabled=true,count=3,selected=0,setSelected})=> (
  <div className="vs-pagination">
    <ul>
        {
            Array.from({ length: count }, (_, index) => {
                return (
                    <li><Button
                        onClick={()=>setSelected(index)}
                        disabled={(index > 0) && disabled} className={selected === index ? "active" : ""} to="/careers">{index + 1}</Button></li>
                )
            })
        }
      {/*<li><Button className="active" to="/careers">1</Button></li>*/}
      {/*<li><Button disabled={disabled} to="/careers">2</Button></li>*/}
      {/*<li><Button disabled={disabled} to="/careers">3</Button></li>*/}
      {/*<li><Button disabled={disabled} to="/careers"><i className="far fa-arrow-right"/></Button></li>*/}
    </ul>
  </div>
);

export default Pagination;