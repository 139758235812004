import React from 'react';
import {InfoMedia} from '../';
import {Link} from "react-router-dom";

const ImageBoxThree = ({imageOne, imageTwo, number, title, icon})=> (
  <div className="img-box3">
    <div className="img-1"><img src={imageOne} alt="About"/></div>
    <div className="img-2"><img style={{height:450,marginLeft:170,marginTop:200}} src={imageTwo} alt="About2"/></div>
      <Link
          target={'_blank'}
          to={'https://esyncross.freshdesk.com/support/login'} >
          <InfoMedia className="award-box align-items-center"
                     icon={icon}
                     info={title}
                     title={number}
          />
      </Link>
  </div>
);

export default ImageBoxThree;