import React, {useEffect, useRef, useState} from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import {Button, List, SecSubTitle, SecTitle, TitleWrap} from '../../components';

const AboutEightWithSideTabs = ({className,title,subtitle,data})=> {

    const [selected, setSelected] = useState(0);

    const [activeTab, setActiveTab] = useState(data[0].id);

    useEffect(() => {
        setSelected(0);
    }, [activeTab]);

  return (
      <div className={`position-relative ${className || ''}`}>
        <div className="service-shape1"></div>
        <div className="container">
          <TitleWrap className="text-center">
            <SecSubTitle>{subtitle}</SecSubTitle>
            <SecTitle className="text-capitalize h1">{title}</SecTitle>
          </TitleWrap>
          <Tab.Container id="serviceTab" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
            <Nav className="service-tab-menu">
              {data.map((item) => (
                  <Nav.Link key={item.id} eventKey={item.id}>
                    <span className="btn-img pt-2"><i className={item.icon + " AboutEightIcons"} /></span>
                    <span className="btn-title h6">{item.title}</span>
                    <span className="btn-text">{item.subtitle}</span>
                  </Nav.Link>
              ))}
            </Nav>
            <Tab.Content >
              {data.map((item) => (
                  <Tab.Pane key={item.id} eventKey={item.id}>
                    <div className="row gx-60 gy-30"  style={{marginBottom:150}}>
                        <div className="col-lg-6">
                            <div className="about-box1" >
                                <h2 className="about-title  h3 text-end">{item.title}</h2>
                                {/*<span className="about-subtitle  d-flex justify-content-end">{item.subtitle}</span>*/}
                                <List className="list-style3">
                                    {item.texts.map((list, index) => (
                                        <div className={"cursor-pointer"} onClick={()=>setSelected(index)}>
                                            <List.Item className={index === selected ? 'SelectedAboutEight' : ''}  key={index}>
                                                <i className={"fas fa-check-circle " + (index === selected ? 'SelectedAboutEight' : '')}/>{list}
                                            </List.Item>
                                        </div>
                                    ))}
                                </List>
                            </div>
                        </div>
                      <div className="col-lg-6">
                        <img src={item.images[selected]} alt="tabthumb" style={{borderRadius: '7px',height:'100%',objectFit:'cover'}} />
                      </div>
                    </div>
                  </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
  );
}

export default AboutEightWithSideTabs;