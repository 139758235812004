import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import {Button, List, SecSubTitle, SecTitle, TitleWrap} from '../../components';

const AboutEight = ({className,title,subtitle,data, listStyle})=> {


  return (
      <div className={`position-relative ${className || ''}`}>
        <div className="service-shape1"></div>
        <div className="container">
          <TitleWrap className="text-center">
            <SecSubTitle>{subtitle}</SecSubTitle>
            <SecTitle className="text-capitalize h1">{title}</SecTitle>
          </TitleWrap>
          <Tab.Container id="serviceTab" defaultActiveKey={data[0].id}>
            <Nav className="service-tab-menu">
              {data.map((item) => (
                  <Nav.Link key={item.id} eventKey={item.id}>
                    <span className="btn-img"><img src={item.icon} alt="tabicon"/></span>
                    <span className="btn-title h6">{item.title}</span>
                    <span className="btn-text">{item.subtitle}</span>
                  </Nav.Link>
              ))}
            </Nav>
            <Tab.Content>
              {data.map((item) => (
                  <Tab.Pane key={item.id} eventKey={item.id}>
                    <div className="row gx-60 gy-30" style={{marginBottom:150}}>
                      <div className="col-lg-6">
                        <img src={item.image}   alt="tabthumb" style={{borderRadius: '7px', width: '100%',height:'100%',objectFit:'cover'}} />
                      </div>
                      <div className="col-lg-6">
                        <div className="about-box2" style={{paddingBlock: 0}}>
                          <h2 className="about-title h3">{item.title}</h2>
                          <span className="about-subtitle">{item.subtitle}</span>
                          <List className={`list-style${listStyle}`}>
                            {item.texts.map((list, index) => (
                                <List.Item key={index}><i className="fas fa-check-circle"/>{list}</List.Item>
                            ))}
                          </List>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
  );
}

export default AboutEight;