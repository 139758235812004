import React from 'react';
import {FeatureBoxOne} from '../../components/';
import Slider from 'react-slick';

const FeatureOne = ({className})=> {

  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {          
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {          
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {          
          slidesToShow: 1,
        }
      }
    ]
  };
  
  return (
    <div className={`feature-wrap1 ${className || ''}`}>
      <div className="container">   
        <Slider className="row" {...settings}>
          <FeatureBoxOne 
            title="SAP Business One Services"
            text="Delivering SAP Business One solution that meet the unique needs of your business, enhancing efficiency and productivity."
            image="images/icon/fe-1-3.png"
            path="/sapb1-implementation"
          />
          <FeatureBoxOne 
            title="Development Services"
            text="Development services include software creation, customization, integration, testing, and maintenance."
            image="images/icon/fe-1-1.png"
            path="/solution-architecture"
          />
          <FeatureBoxOne 
            title="Business Analytics"
            text="Business analytics on SAP B1 includes data analysis, reporting, dashboards, insights, and decision-making."
            image="images/icon/fe-1-5.png"
            path="/business-analytics"
          />
        </Slider>
      </div>
    </div>
  );
}

export default FeatureOne;