import React from 'react';
import {Button, ImageBoxOne, InfoMedia, SecSubTitle, SecTitle} from '../../components';

const AboutOne = ({className})=> (
  <div className={`position-relative ${className || ''}`}>    
    <div className="about-shape1 d-none d-xl-block">eSyncross</div>
    <div className="container z-index-common">        
      <div className="row gx-60">
        <div className="col-lg-6 col-xl-5 mb-50 mb-lg-0">
          <ImageBoxOne imageOne="images/about/ab-1-1.jpg"
            imageTwo="images/about/ab-1-2.jpg"
            video="https://www.dropbox.com/s/b4k0p5swok5l8t2/videoplayback.mp4?dl=1"
          />
        </div>
        <div className="col-lg-6 col-xl-7 align-self-center">
          <SecSubTitle><i className="fas fa-bring-forward"/>Get the Best IT Solutions</SecSubTitle>
          <SecTitle className="h1">Trust Our Elemental IT Solutions for Your Business</SecTitle>
          <p className="mb-4 mt-1 pb-3">At eSyncross, we deliver cross-platform solutions that enhance business efficiency and drive innovation. Our expert team is dedicated to providing world-class IT services tailored to meet the unique needs of your business. Experience seamless integration, superior support, and innovative technology that propels your business forward</p>

          <div style={{width:'fit-content'}}>
            <a href={"tel:+971585703053"} className={`info-media call-media `}>
              <div className="media-icon">
                <img src={"images/icon/tel-1-1.png"} alt="infoIcon"/>
              </div>
              <div className="media-body">
                <span className="media-label">You can always reach to us</span>
                <div className="media-link">Call us: +971 585703053</div>
              </div>
            </a>
            <div  style={{width:'auto',justifyContent:'space-between'}} className="hero-btns ">
              <Button path={'/services'}>Services<i className="far fa-arrow-right"/></Button>
              <Button path={'/products'} className="style2">Solutions<i className="far fa-arrow-right"/></Button>
            </div>
          </div>

        </div>
      </div>
    </div>    
  </div>
);

export default AboutOne;