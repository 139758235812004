import React, {useState} from 'react';
import Slider from "react-slick";
import {Button, IconButton, InfoMedia, SecSubTitle, SecTitle} from '../../components';
import {useNavigate} from "react-router-dom";
import {id} from "ajv";


// Testimonial Data


const ServicesNew = ({className, data, type}) => {

    const [activeItem, setActiveItem] = useState(data[0])
    const [activeSubItems, setActiveSubItems] = useState(data[0].subItems)
    const navigate = useNavigate();
    return (
        <div className={`background-image ${className || ''}`}
             style={{backgroundImage: 'url(images/bg/testi-bg-2-1.jpg)'}}>
            <div className="container position-relative">

                <SecTitle className="text-capitalize h1">Our {type}</SecTitle>
                <div className="row gx-60">
                    <div className="col-lg-3 col-xl-3">
                        <div className="testi-author-slide row">
                            {data.map((item, idx) => (
                                <div key={idx} onClick={() => {
                                    setActiveSubItems(item.subItems)
                                    setActiveItem(item)
                                }}>
                                    <InfoMedia
                                        styleFont={{fontSize: item.title?.length < 25 ? null : 16}}
                                        className={`p-3 author-style1 align-items-center ${item === activeItem ? `author-active` : ``}`}
                                        title={item.title}
                                        icon={item.icon}
                                        styleIcon={{
                                            backgroundColor: '#1B3C69', height: 60,
                                            color: 'white', fontSize: 22,
                                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="col-lg-9 col-xl-9">
                        {activeSubItems.map((item, idx) => (
                            <div key={idx} style={{
                                backgroundColor: "aliceblue",
                                borderRadius: 20,
                                marginBottom: 20,
                                padding: 20
                            }}>
                                <div className="row" >
                                    <div className={'col-lg-4 col-xl-4 gy-2'}>
                                        <img style={{height: 250, width: 300, objectFit: item.objectFit}} src={item.imgPath}
                                             alt="testmonial"/>
                                    </div>
                                    <div className="col-lg-7 col-xl-7 testi-text-slide gx-0 gy-2" style={{marginLeft: 30}}>
                                        <SecSubTitle className={"m-0"}><i
                                            className="fas fa-bring-forward"/>{item.reference}</SecSubTitle>
                                        <div key={item.id}>
                                            <SecTitle className="text-capitalize m-0">{item.title}</SecTitle>
                                            <p className="testi-text" style={{fontSize: 15}}>{item.description}</p>
                                        </div>
                                        {item.link !== '#' && <Button path={item.link}>Read More<i className="far fa-arrow-right"/></Button>}
                                    </div>

                                </div>
                            </div>))}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ServicesNew;