import React from 'react';
import {CategoryWidget, GalleryWidget, SearchWidget, TagWidget, Widget} from '../../components';

// Widgets Data
import widgetsData from '../../data/widgets.json';
import RecentCareersWidget from "../../components/Widgets/RecentCareersWidget";
import careerdata from "../../data/career.json";
import {Link} from "react-router-dom";

const {recentCareers, blogTags, galleryImages, SAPB1ServicesList} = widgetsData;

const SidebarThree = ({className,handleSearch})=> (
  <aside className={`sidebar-area ${className || ''}`}>
    <Widget>
      <SearchWidget handleSearch={handleSearch}/>
    </Widget>
    <Widget widgetTitle="Recent Careers">
      {careerdata?.slice(0,2).map(career => (
        <RecentCareersWidget key={career.id}
          title={career.title}
          image={career.image}
          // date={career.date}
          path={'/career-details/'+career.id}
        />
      ))}
    </Widget>
    <Widget widgetTitle="Gallery Careers">
      <GalleryWidget>
        {careerdata.map((career, index) => (
            <Link to={'/career-details/'+career?.id}>
                <GalleryWidget.Item key={index} src={career?.image} alt="thumb"/>
            </Link>
        ))}
      </GalleryWidget>
    </Widget>
  </aside>
);

export default SidebarThree;