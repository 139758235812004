import React from 'react';
import {FeatureBoxTwo, SecSubTitle, SecTitle, TitleWrap} from '../../components/';
import Slider from 'react-slick';

const FeatureTwo = ({className, title, subTitle, features, featuresHeightClass})=> {
  
  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className={`feature-wrap2 ${className || ''}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xxl-3 text-center text-xxl-start">
            <TitleWrap>
              <SecSubTitle><i className="fas fa-bring-forward"/>{subTitle}</SecSubTitle>
              <SecTitle className="h1 text-capitalize">{title}</SecTitle>
            </TitleWrap>
          </div>
          <div className="col-xxl-9">
            <Slider className="row feature-slide2" {...settings}>
              {
                features?.map(feature => (
                    <FeatureBoxTwo
                        featuresHeightClass={featuresHeightClass}
                        title={feature.title}
                        image={feature.image}
                        text={feature?.text}
                    />
                ))
              }
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureTwo;