import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {
        AboutFive,
        AboutFour,
        AboutThree,
        ContactTwo,
        FeatureTwo,
        FooterOne,
        HeaderOneCopy,
        ProcessTwo, TestimonialTwo
} from '../containers';


const features = [
        {
                title: "SAP B1 Components (Automation)",
                image: "images/icon/fe-2-1.png",
                text: "Integrates complex scenarios between SAP B1 companies, automates workflows, and more"
        },
        {
                title: "Third Party Systems",
                image: "images/icon/fe-2-2.png",
                text: "Any system or software that completes a process or a workflow, like WMS, HR, Banks, Government, and more"
        },
        {
                title: "Hardware Devices",
                image: "images/icon/fe-2-3.png",
                text: "Machines, devices, that stores data into SAP B1."
        },
]
const list = ["Extend Existing functionalities","Create new functions and features","Automate workflow","Customize and Integrate Screens",]
const SapB1Addon = () => (
    <Fragment>
        <Seo title="SAP Business One Addon"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="Addon Development & Customizations"
                    pageSubTitle={"EXTEND SAP BUSINESS ONE FEATURES"}
                    pageDesc={"We customize functionalities to enhance SAP Business One capabilities."}
                    bgImage="images/bg/bg-sap.png"/>


            <AboutFive title={"Explore unlimited possibilities and functionalities"}
                       subTitle={"NO LIMITS"}
                       description={"We develop and implement additional features tailored to your specific business needs, extending the functionality of SAP Business One."}
                       number={"100+"}
                       images={[
                               'images/esyncross/eSyncross (38).jpeg',
                               'images/esyncross/eSyncross (53).jpeg',
                               'images/esyncross/eSyncross (29).jpeg',
                       ]}
                       mediaTitle={"Addons Built"}
                       bottomList={list}
                       buttonPath={"/about"}
                       className="space-top space-extra-bottom"/>
            <ProcessTwo className="space-top space-extra-bottom"/>

            {/*<FeatureTwo features={features} title={"What Our Team Integrates"} subTitle={"TRUSTED DEVELOPERS"} className="space-top" featuresHeightClass={"ht-425"}/>




            <TestimonialTwo className="space-top space-extra-bottom"/>*/}
        <FooterOne/>
        <ScrollTopBtn/>
    </Fragment>
);

export default SapB1Addon;