import React, {useEffect, useState} from 'react';
import {Button} from '../';
import widgetsData from "../../data/widgets.json";
import emailjs from 'emailjs-com';
import Loader from "../Loader/Loader";
import {toast, ToastContainer} from "react-toastify";


export const SERVICE_ID = "service_atpc758";
export const TEMPLATE_ID = "template_42k7j9v";
export const PUBLIC_KEY = "0-C3IYGQO6vF_L11c";

const FormFive = ({initialService}) => {

    const formHandle = (event) => {
        event.preventDefault();
    }

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [service, setService] = useState(initialService);
    const [message, setMessage] = useState('');
    const [loader, setLoader] = useState(false);

    const [isValidName, setIsValidName] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isValidMessage, setIsValidMessage] = useState(false);
    const [showValidation, setShowValidation] = useState(false);

    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    useEffect(() => {
        setIsValidName(name !== '')
        setIsValidEmail(re.test(email))
        setIsValidMessage(message !== '')
    }, [name, email, message])

    const sendEmail = async () => {
        if (!isValidName || !isValidEmail || !isValidMessage) {
            toast.warn(isValidName && isValidMessage && (email !== '') ?
                'Please type a valid email.'
                :
                'Please fill all fields first.')
            setShowValidation(true)
            return;
        }
        setShowValidation(false)

        const templateParams = {
            to_email: 'sales@esyncross.com', // Replace with actual recipient email
            name: name,
            email: email,
            service: service,
            message: message,
        };

        setLoader(true)
        emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, PUBLIC_KEY)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                // setEmail('')
                // setName('')
                // setMessage('')
                setLoader(false)
                toast.success('Email sent successfully')
            }, () => {
                setLoader(false)
                toast.error("Failed to send email")
            });
    };
    const {SAPB1ServicesList, DevelopmentServicesList, CloudServicesList} = widgetsData;

    return (
        <div className="vs-contact-form">
            <div className="row gx-20">
                <div className={"col-md-6 form-group" + (showValidation && !isValidName ? " invalidInput":"")}>
                                    <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text" placeholder="Your Name"/>
                    <i className="fal fa-user"/>
                </div>
                <div className={"col-md-6 form-group" + (showValidation && !isValidEmail ? " invalidInput":"")}>
                    <input value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           type="email" placeholder="Email Address"/>
                    <i className="fal fa-envelope"/>
                </div>
                <div className={"col-12 form-group"}>
                    <select value={service} onChange={(event) => setService(event.target.value)}>
                        {
                            [...SAPB1ServicesList, ...DevelopmentServicesList, ...CloudServicesList]?.map((item, index) => {
                                return (
                                    <option key={index} value={item}>{item}</option>
                                )
                            })
                        }

                    </select>
                </div>
                <div className={"col-12 form-group" + (showValidation  && !isValidMessage ? " invalidInput":"")}>
          <textarea value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type Your Message"/>
                </div>
                <div className="col-12">
                    <Button onclick={sendEmail}>Submit Message<i className="far fa-arrow-right"/></Button>
                </div>
            </div>
            {loader && <Loader/>}
        </div>
    );
}

export default FormFive;