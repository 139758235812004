import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const CategoryWidget = ({ children, className = '' }) => (
    <ul className={`widget-cat-list ${className}`}>
        {children}
    </ul>
);

CategoryWidget.Item = ({ children, path, margin = false ,selected}) => {
    const isExternal = path?.startsWith('http');
    const isNotScroll = path?.startsWith('/');

    return (
        <li  key={path} style={{ marginBottom: margin ? 0 : 18 }}>
            {isExternal ? (
                <a  target={'_blank'} href={path}>{children}</a>
            ) : isNotScroll ? (
                    <RouterLink className={` ${selected ? "cat-selected" : ""}`}     style={{marginBottom: margin ? 0 : 18}} to={path}>{children}</RouterLink>
                ) : (
                    <ScrollLink
                        to={path}
                        smooth={true}
                        duration={1000}
                        offset={70}
                        style={{marginBottom: margin ? 0 : 18}}
                        className={`cursor-pointer`}>
                        {children}
                    </ScrollLink>
                )}
        </li>
    );
};

export default CategoryWidget;
