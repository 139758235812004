import React, {useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {List} from '../../components';

// FAQ Data
import faqData from '../../data/faq.json';
import widgetsData from "../../data/widgets.json";
import SidebarServices from "../Sidebars/SidebarServices";

const {SAPB1ServicesList, DevelopmentServicesList, CloudServicesList} = widgetsData;

const ServiceSingle = ({...restProps})=> {

  const [service, setService] = useState('Implementation');
  const catClick = (cat) => {
    // let allServices = [...SAPB1ServicesList,...DevelopmentServicesList,...CloudServicesList]
    setService(cat)
  }

    return(
        <div {...restProps}>
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-lg-4 sidebar-service-sm">
                <SidebarServices catClick={catClick}/>
              </div>
              <div className="col-lg-8">
                <div className="mb-3 pb-3"><img src="images/service/sr-d-1-1.jpg" alt="Project"/></div>
                <h2 className="h4">{service}</h2>
                <p>
                  Implementing SAP Business One involves a comprehensive approach to deploying this robust ERP solution tailored to meet the unique needs of small to midsize businesses. Our service ensures a smooth transition from legacy systems to SAP Business One, providing end-to-end support throughout the entire implementation process.
                </p>
                <div className="row gx-0 mb-4 pb-2 pt-3">
                  <div className="col-xl-5"><img src="images/service/sr-d-1-2.jpg" alt="project" className="w-100"/></div>
                  <div className="col-xl-7">
                    <div className="service-list-box">
                      <h3 className="h5 title">Service Features</h3>
                      <List className="list-style3">
                        <List.Item><i className="fal fa-check-circle"/>Define scope, objectives,  kick-off meeting.</List.Item>
                        <List.Item><i className="fal fa-check-circle"/>Document requirements, and identify gaps.</List.Item>
                        <List.Item><i className="fal fa-check-circle"/>Configure, customize, migrate data, and test.</List.Item>
                        <List.Item><i className="fal fa-check-circle"/>Train users, test integrations, and plan cutover.</List.Item>
                        <List.Item><i className="fal fa-check-circle"/>Go-Live and Support.</List.Item>
                      </List>
                    </div>
                  </div>
                </div>
                <h3 className="h5">Why Customers Should Move to SAP Business One</h3>
                <p>
                  SAP Business One is an ERP solution designed for small to midsize businesses, providing tools to manage sales, distribution, and financials. By moving to SAP Business One, customers gain real-time data access, streamlined operations, and better decision-making. Its scalability ensures it grows with your business, while the user-friendly interface enhances productivity. Additionally, powerful reporting and analytics offer deeper insights for strategic growth. Continuous support and innovation from SAP help businesses stay competitive in a changing market.
                </p>
                <div className="row pt-3 mb-30 pb-10 gy-30">
                  <div className="col-md-6"><img src="images/service/sr-d-1-3.jpg" alt="project"/></div>
                  <div className="col-md-6"><img src="images/service/sr-d-1-4.jpg" alt="project"/></div>
                </div>
                <Accordion defaultActiveKey={faqData[0].questionId} className="accordion-style1 layout2">
                  {faqData.map(item => (
                      <Accordion.Item key={item.questionId} eventKey={item.questionId}>
                        <Accordion.Header>{item.question}</Accordion.Header>
                        <Accordion.Body><p>{item.answer}</p></Accordion.Body>
                      </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="col-lg-4">
                <SidebarServices catClick={catClick}/>
              </div>
            </div>
          </div>
        </div>
        )

};

export default ServiceSingle;