import React from 'react';
import {ImageBoxFour, InfoMedia, SecSubTitle, SecTitle} from '../../components';

const AboutFour = ({data,className,reverse=true})=> (
  <div className={className}>
    <div className="container">
      <div className={`row ${reverse ? "flex-row-reverse" : ""}`}>
        <div className="col-lg-6 mb-30">
          <ImageBoxFour 
            imageOne={data?.images ? data?.images[0] : "images/about/ab-3-1.jpg"}
            imageTwo={data?.images ? data?.images[1] : "images/about/ab-3-2.jpg"}
          />
        </div>
        <div className="col-lg-6 mb-30 pt-10 pt-lg-0 text-center text-md-start">
          <SecSubTitle><i className="fas fa-bring-forward"/>{data.subtitle}</SecSubTitle>
          <SecTitle className="h1 mb-3 pb-3 normal-title">{data.title}</SecTitle>
          {
            data.points.map((item,index)=>{
              return (
                  <InfoMedia className="media-order"
                             number={"0"+(index+1)}
                             title={item.title}
                             info={item.text}
                  />
              )
            })
          }

        </div>
      </div>
    </div>
  </div>
);

export default AboutFour;