import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {ImageBoxTwo, SecSubTitle, SecTitle} from '../../components';

// FAQ Data

const FaqTwo = ({className,data})=> (
  <div className={`faq-wrap1 ${className || ''}`}>
    <div className="faq-shape1 background-image" style={{backgroundImage: 'url(images/bg/faq-bg-1-1.jpg)'}}></div>
    {/*<div className="faq-shape2 background-image" style={{backgroundImage: 'url(images/bg/faq-bg-1-2.jpg)'}}></div>    */}
    <div className="container">
      <div className="row gx-60">
        <div className="col-lg-6 pb-20 pb-lg-0">
          <ImageBoxTwo 
            imageOne="images/faq/faq-1-1.jpg"
            imageTwo="images/customsAddon/customs-addon-1.png"
            video=""
          />
        </div>
        <div className="col-lg-6 align-self-center">
          <SecSubTitle className=""><i className="fas fa-bring-forward"/>{data.subtitle}</SecSubTitle>
          <SecTitle className="text-white mb-4 pb-2 h1">{data.title}</SecTitle>
          <Accordion defaultActiveKey={data.points[0].id} className="accordion-style1">
            {data.points.map(item => (
              <Accordion.Item key={item.id} eventKey={item.id}>
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body><p>{item.text}</p></Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  </div>
);


export default FaqTwo;