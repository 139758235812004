import React, {useState} from 'react';
import Slider from "react-slick";
import {IconButton, InfoMedia, SecSubTitle, SecTitle} from '../../components';
import ReactPlayer from "react-player";


// Testimonial Data


const SAPB1Video = ({className, video, title, subTitle, secTitle, description}) => {


    return (
        <div className={`background-image ${className || ''}`}
             style={{backgroundImage: 'url(images/bg/testi-bg-2-1.jpg)'}}>
            <div className="container position-relative">

                <div className="row gx-60">
                    <SecTitle className="text-capitalize h1">{title}</SecTitle>
                    <div className="col-lg-5 col-xl-6">
                        <div>
                            <ReactPlayer url={video} className='react-player' width='100%' height='100%' controls playing />
                        </div>
                    </div>
                    <div className="col-lg-7 col-xl-6 ">
                        <SecSubTitle><i className="fas fa-bring-forward"/>{subTitle}</SecSubTitle>
                        <div>
                            <SecTitle className="text-capitalize h1">{secTitle}</SecTitle>
                            <p className="testi-text">{description}</p>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SAPB1Video;