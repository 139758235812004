import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {FooterOne, HeaderOneCopy, ProjectThree} from '../containers';

const Project = ()=> (
  <Fragment>
    <Seo title="Projects" />
    <HeaderOneCopy />
    <Breadcrumb
        pageName="Projects"
        pageSubTitle={"Innovative Solutions for Tomorrow's Challenges"}
        pageDesc={"Showcases innovative projects with forward-thinking solutions for current and future challenges."}
        bgImage="images/bg/bg-image-1.png" />
    <ProjectThree className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default Project;