import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {ContactTwo, FooterOne, HeaderOneCopy} from '../containers';

const BePartner = () => (
  <Fragment>
    <Seo title="Become a partner" />
    <HeaderOneCopy />
    <Breadcrumb pageName="Become a partner"
                pageSubTitle={"Join Us in Shaping the Future"}
                pageDesc={"Emphasize mutual benefits and shared vision of the partnership."}
                bgImage="images/bg/bg-image-6.png" />
    <ContactTwo initialService={"Become a partner"} className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default BePartner;