import React from 'react';
import {ProcessBoxOne, SecSubTitle, SecTitle, TitleWrap} from '../../components';

// Process Data
import processData from '../../data/processTwo.json';

const ProcessTwo = ({className,data})=> (
  <div className={`background-image ${className || ''}`} style={{backgroundImage: 'url(images/bg/process-bg-2-1.jpg)'}}>    
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-xl-6">
          <TitleWrap>
            <SecSubTitle>OUR WORK PROCESS</SecSubTitle>
            <SecTitle className="h1">HOW WE DO IT</SecTitle>
          </TitleWrap>
        </div>
      </div>
      <div className="row justify-content-between">
        {(data || processData).map(item => (
          <ProcessBoxOne key={item.number} className="col-md-4 col-xl-auto process-style2"
            arrow={item.arrow2} 
            title={item.title} 
            text={item.text} 
            icon={item.icon} 
            number={item.number}
            arrowOffset={"-30%"}
          />
        ))}
      </div>
    </div>
  </div>
);

export default ProcessTwo;