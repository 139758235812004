import React from 'react';
import {Link} from 'react-router-dom';

const FeatureBoxTwo = ({image, title, text, path, featuresHeightClass})=> (
  <div className={`feature-style2 ${featuresHeightClass || ''}`}>
    <div className="feature-icon">
      <img src={image} alt="Features"/>
    </div>
    <h3 className="feature-title h5"><div className="" to={path}>{title}</div></h3>
    <p className="feature-text">{text}</p>
      {path && <Link to={path} className="link-btn">Read Details<i className="far fa-arrow-right"/></Link>}
  </div>
);

export default FeatureBoxTwo;