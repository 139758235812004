import React from 'react';
import {BannerWidget, CategoryWidget, Widget} from '../../components';


// Widgets Data
import widgetsData from '../../data/widgets.json';

const {SAPB1ServicesList, DevelopmentServicesList, CloudServicesList} = widgetsData;

const SidebarServices = ({className,catClick}) => (
    <aside className={`service-sidebar ${className || ''}`}>
        <Widget widgetTitle="SAP Business One Services" className="widget_nav_menu">
            <CategoryWidget>
                {SAPB1ServicesList.map(cat => (
                    <div className={"category-div"} onClick={()=>catClick(cat)}>
                        <CategoryWidget.Item margin={true} key={cat}>{cat}</CategoryWidget.Item>
                    </div>
                ))}
            </CategoryWidget>
        </Widget>
        <Widget widgetTitle="Development Services" className="widget_nav_menu">
            <CategoryWidget>
                {DevelopmentServicesList.map(cat => (
                    <div className={"category-div"} onClick={()=>catClick(cat)}>
                        <CategoryWidget.Item margin={true} key={cat}>{cat}</CategoryWidget.Item>
                    </div>
                ))}
            </CategoryWidget>
        </Widget>
        <Widget widgetTitle="Cloud Management Services" className="widget_nav_menu">
            <CategoryWidget>
                {CloudServicesList.map(cat => (
                    <div className={"category-div"} onClick={()=>catClick(cat)}>
                        <CategoryWidget.Item margin={true} key={cat}>{cat}</CategoryWidget.Item>
                    </div>
                ))}
            </CategoryWidget>
        </Widget>
        <BannerWidget
            title="Have Any Query?"
            image="images/widget/quote-box.jpg"
            btnText="Contact Us"
            path="/contact"
        />
    </aside>
);

export default SidebarServices;