import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {BlogOne, FooterOne, HeaderOneCopy} from '../containers';

const Blog = () => (
  <Fragment>
    <Seo title="Blog List" />
    <HeaderOneCopy />
    <Breadcrumb
        pageName="Blog List"
        pageSubTitle={"Stay Informed with the Latest Insights"}
        pageDesc={"Stay updated on digital transformation and ERP. Explore our blog."}
        bgImage="images/bg/bg-image-4.png" />
    <BlogOne className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default Blog;