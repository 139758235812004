import React from 'react';
import {PlayBtn} from '../';

const ImageBoxOne = ({imageOne, imageTwo, video})=> (
  <div className="img-box1">
    <div className="img-1">
      <img
          // style={{width:425,height:508,objectFit:'cover',objectPosition:'center'}}
          src={imageOne} alt="imgOne"/>
    </div>
    <div className="img-2">
      <img
          // style={{width:344,height:280,objectFit:'cover',objectPosition:'right'}}
           src={imageTwo} alt="imgTwo"/>
      {/*<PlayBtn path={video} className="style2 position-center"><i className="fas fa-play"/></PlayBtn>*/}
    </div>
  </div>
);

export default ImageBoxOne;