import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {
    AboutFive,
    AboutFour,
    AboutThree, BrandTwo,
    ContactTwo,
    FeatureTwo,
    FooterOne,
    HeaderOneCopy,
    ProcessTwo, ServiceThree, TestimonialTwo
} from '../containers';
import productsData from '../data/products.json';
import BizVaultData from "../data/BizVault.json";
import AboutEight from "../containers/Abouts/AboutEight";
import AboutEightWithSideTabs from "../containers/Abouts/AboutEightWithSideTabs";
import ServiceThreeDynamic from "../containers/Services/ServiceThreeDynamic";



const aboutEightData = [
    {
        id: 'tab1',
        title: 'Stock Management',
        subtitle: '',
        icon: 'fas fa-boxes',
        images: [
            'images/evo/stock-Inbound-1.png',
            'images/evo/stock-Inbound-2.png',
            'images/evo/stock-Inbound-3.png',
            'images/evo/stock-Inbound-4.png',
        ],
        texts: [
            "Goods Receipt Note (GRN) : Streamlining Operations with Comprehensive GRN Management",
            "Sales Return : Explore Your Return Process",
            "Putaway : The Heart of Your Warehouse: Putaway Excellence",
            "Expected & Open Purchase Orders : The Heart of Your Warehouse: Putaway Excellence"
        ],
        feature: [],
        btntext: '',
        path: '/service-details'
    },
    {
        id: 'tab2',
        title: 'Order Fulfillment',
        subtitle: '',
        icon: 'fas fa-shipping-fast',
        images: [
            'images/evo/stock-Inbound-1.png',
            'images/evo/stock-Outbound-2.png',
            'images/evo/stock-Outbound-3.png',
            'images/evo/stock-Outbound-4.png',
        ],
        texts: [
            "Sales Delivery : Organize and manage your orders to ensure the successful completion of your deliveries.",
            "Purchase Return : Efficient and Hassle-Free Supplier Return Process.",
            "Picklist : Efficient Order Fulfillment. The Power of Picklists.",
            "Expected & Open Sales Orders : a list of sales orders that a business expects to fulfill in the near future"
        ],
        feature: [],
        btntext: '',
        path: '/service-details'
    },
    {
        id: 'tab3',
        title: 'Inventory Control',
        subtitle: '',
        icon: 'fas fa-balance-scale',
        images: [
            'images/evo/stock-adj-1.png',
            'images/evo/stock-adj-2.png',
        ],
        texts: [
            "Cycle Count (Stock Taking) : Accurate stock taking is essential for efficient inventory management.",
            "Stock Adjustments : Balancing Act: Stock Adjustments for Warehouse Harmony",
        ],
        feature: [],
        btntext: '',
        path: '/service-details'
    },
    {
        id: 'tab4',
        title: 'Team & Operations',
        subtitle: '',
        icon: 'fas fa-users',
        images: [
            'images/evo/team-1.png',
            'images/evo/team-2.png',
            'images/evo/stock-Outbound-3.png',
            'images/evo/team-4.png',
            'images/evo/team-5.png',
        ],
        texts: [
            "Team Management : Efficiently manage teams of all sizes",
            "Items, Serials & Batches Management : Track and manage all inventory items",
            "Picking Management : Streamline and optimize the picking process",
            "Reporting & Analytics : Generate insightful reports and analytics",
            "Printing : Seamlessly print labels and documents",
        ],
        feature: [],
        btntext: '',
        path: '/service-details'
    },
];

const aboutFiveData = {
    title: "Optimize efficiency and accomplish tasks swiftly.",
    subtitle: "Unify and Simplify Tasks",
    points: [
        {
            id: "1",
            image: "images/service/sr-3-1.jpg",
            icon: "images/icon/process-2-1.png",
            bgShape: "images/bg/sr-box-bg-1.jpg",
            bgShape2: "images/shape/sr-bg-shape-2-1.png",
            title: "Document Management",
            text: "Efficient document management for business processes. Integration with ERP systems.",
            subPoints: ["Customers", "Suppliers", "Employees"]
        },
        {
            id: "1",
            path: "/services",
            image: "images/service/sr-3-1.jpg",
            icon: "images/icon/fe-2-2.png",
            bgShape: "images/bg/sr-box-bg-1.jpg",
            bgShape2: "images/shape/sr-bg-shape-2-1.png",
            title: "Hierarchical structure",
            text: "Hierarchical User Roles for precise control. Layered structure ensures secure project management.",
            subPoints: ["Admins", "Users", "Team Members", "Business Partners"]
        },
        {
            id: "1",
            path: "/services",
            image: "images/service/sr-3-1.jpg",
            icon: "images/icon/sr-icon-3-5.png",
            bgShape: "images/bg/sr-box-bg-1.jpg",
            bgShape2: "images/shape/sr-bg-shape-2-1.png",
            title: "Improve collaboration",
            text: "Enhance team collaboration, centralize project communications for better efficiency.",
            subPoints: ["Comments", "Docs", "Whiteboards", "Clips"]
        },
        {
            id: "1",
            path: "/services",
            image: "images/service/sr-3-1.jpg",
            icon: "images/icon/sr-icon-3-3.png",
            bgShape: "images/bg/sr-box-bg-1.jpg",
            bgShape2: "images/shape/fe-1-2.png",
            title: "Enhance visibility",
            text: "Gain clear visibility into tasks and responsibilities, ensuring accountability.",
            subPoints: ["Dashboards", "Reports"]
        },
        {
            id: "1",
            path: "/services",
            image: "images/service/sr-3-1.jpg",
            icon: "images/icon/process-1-2.png",
            bgShape: "images/bg/sr-box-bg-1.jpg",
            bgShape2: "images/shape/process-1-2.png",
            title: "Automate work",
            text: "Streamline business processes with powerful automations, improve operational efficiency.",
            subPoints: ["Templates", "Reports"]
        },
    ]
};
const BizVault = () => (
    <Fragment>
        <Seo title="BizVault"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="BizVault"
                    pageSubTitle={"Designed to manage any type of document."}
                    pageDesc={"Boost productivity by simplifying tasks in a unified space." }
                    bgImage="images/bg/bg-image-2.png"/>

        <ServiceThreeDynamic
            className="space-top space-extra-bottom"
            data={aboutFiveData}
        />



        {/*<AboutEightWithSideTabs*/}
        {/*    data={aboutEightData}*/}
        {/*    title={"Optimal for account management needs"}*/}
        {/*    subtitle={"Quickly initiate with pre-configured solutions."}*/}
        {/*    className="space-top space-extra-bottom"/>*/}

        <TestimonialTwo
            bigImage={true}
            title={"One app to replace them all"}
            subtitle={"Get everyone working in a single platform"}
            data={BizVaultData} className="space-top space-extra-bottom"/>

        <FooterOne/>

        <ScrollTopBtn/>
    </Fragment>
);

export default BizVault;