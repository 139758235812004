import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import SidebarOne from '../Sidebars/SidebarOne';
import {
    BlockQuote,
    Blog,
    BlogAuthor,
    CommentForm,
    CommentList,
    IconLink,
    PostPagination,
    TagWidget
} from '../../components';
import blogData from "../../data/blog.json";

// Blog Comments


const BlogSingle = ({className, id}) => {
    const blog = blogData?.find(blog => blog?.id === parseInt(id));
    const index = blogData?.findIndex(blog => blog?.id === parseInt(id))

    return (
        <section className={`vs-blog-wrapper blog-details ${className || ''}`}>
            <div className="container">
                <div className="row gx-40">
                    <div className="col-lg-8">
                        <Blog className="blog-single">
                            <Blog.Image src={blog.images[0]} alt="Thumb"/>
                            <Blog.Body>
                                <Blog.Meta>
                                    <Link style={{cursor:'auto'}}><i className="far fa-calendar"/>{blog.date}</Link>
                                    <Link style={{cursor:'auto'}}><i className="fal fa-user"/>by {blog.authorName}</Link>
                                </Blog.Meta>
                                <Blog.Title>{blog.title}</Blog.Title>
                                <p style={{whiteSpace: 'pre-wrap'}}>{blog.text}</p>
                                {
                                    blog.points.map((point, index) => {
                                        return (
                                            <>
                                                <p style={{fontSize: 18, marginBottom: 0}}>
                                                    <b>{index + 1}. {point.title}</b></p>
                                                <p style={{whiteSpace: 'pre-wrap'}}>{point.text}</p>
                                                {
                                                    index === 3 &&
                                                    <div className="row mt-30">
                                                        <div className="col-md-6 mb-30 col-lg-6 col-md-12">
                                                            <img style={{height:250,width:'100%',objectFit:'cover'}} src={blog.images[1]} alt="Blog"/>
                                                        </div>
                                                        <div className="col-md-6 mb-30 col-lg-6 col-md-12">
                                                            <img style={{height:250,width:'100%',objectFit:'cover'}} src={blog.images[2]} alt="Blog"/>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )
                                    })
                                }
                                <p style={{whiteSpace: 'pre-wrap', marginTop: 100}}>{blog.conclusion}</p>

                                {/*<BlockQuote*/}
                                {/*    text="Holisticly build technologies expanded array relationships productize professional tailers rather mesh stand"*/}
                                {/*    author="William Benjamin"*/}
                                {/*/>*/}


                                <PostPagination>
                                    {console.log(index)}
                                    {index !== 0 &&
                                        <PostPagination.Post path={`/blog-details/${blogData[index-1]?.id}`} image={blogData[index-1]?.images[0]}
                                                             title="Previous Post"
                                                             className="prev"/>
                                    }
                                    <PostPagination.Icon name="fas fa-th" path="/blog"/>
                                    {index !== (blogData?.length - 1) &&
                                        <PostPagination.Post path={`/blog-details/${blogData[index+1]?.id}`} image={blogData[index+1]?.images[0]}
                                                          title="Next Post" className="next"/>
                                    }
                                </PostPagination>
                                {/*<BlogAuthor*/}
                                {/*    image="/images/blog/blog-author.jpg"*/}
                                {/*    name="William Benjamin"*/}
                                {/*    designation="Author"*/}
                                {/*    text="Re-engineer multimedia based internal or organic sources for progressive vortals. Assertively leverage existing economically sound total linkage whereas global best practices."*/}
                                {/*/>*/}
                                {/*<CommentList title="Comments" comment={blogComment}/>*/}
                                {/*<CommentForm title="Leave a Comment" subtitle="Your email address will not be published. Required fields are marked*"/>*/}
                            </Blog.Body>
                        </Blog>
                    </div>
                    <div className="col-lg-4">
                        <SidebarOne/>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default BlogSingle;
