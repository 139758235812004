import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {AboutFour, AboutThree, ContactTwo, FeatureTwo, FooterOne, HeaderOneCopy} from '../containers';


const features = [
        {
                "title": "Proven Project Methodology",
                "image": "images/icon/fe-2-1.png",
                "text": "We use a formal methodology ensuring consistency, quality, and alignment with your business goals."
        },
        {
                "title": "Experienced Project Managers",
                "image": "images/icon/fe-2-2.png",
                "text": "Our certified project managers provide governance, progress tracking, and formal project management."
        },
        {
                "title": "Certified Consultants",
                "image": "images/icon/fe-2-3.png",
                "text": "\"Certified experts configure, integrate, and customize ERP solutions to precisely meet your unique requirements."
        }
]


const aboutFourData = {
        title:'Make eSyncross Your Partner',
        subtitle:'TRUSTED SERVICE FROM A TRUSTED PARTNER ',
        images:[
            'images/esyncross/eSyncross (28).jpeg',
            'images/esyncross/eSyncross (16).jpeg',
        ],
        points:[
                {
                        title:"Rely on Our Best Business Practices ",
                        text:"Our strength is in our approach. Whether your implementation is simple or involves multiple locations, multiple tiers, global subsidiaries, or other complexities, our ERP implementation methodology minimizes risk and gets you to success.",
                },
                {
                        title:"Trust Out knowledge in business diversity ",
                        text:"consultants have unparalleled experience and knowledge across a wide range of industries, meaning you’ll get the best ERP solutions specific to your unique needs",
                },
                {
                        title:"Long Journey Partner",
                        text:"With a forward-thinking mindset, our ERP implementation services set you up for success— today and tomorrow. Protect your investment and be future-ready.",
                },
        ]
}

const SapB1Implementation = () => (
    <Fragment>
        <Seo title="SAP Business One Transformation & Implementation"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="SAP Business One Implementation"
                    pageSubTitle={"USE TECHNOLOGY TO YOUR ADVANTAGE"}
                    pageDesc={"Our proven methodology ensures a smooth, successful, and transformative journey."}
                    bgImage="images/bg/bg-sap.png"/>

        <FeatureTwo features={features} title={"What Our Team Brings to You"} subTitle={"Trust our team"} className="space-top" featuresHeightClass={""}/>
        <AboutThree/>
        <AboutFour data={aboutFourData} className="space space-extra-bottom"/>
        <FooterOne/>
        <ScrollTopBtn/>
    </Fragment>
);

export default SapB1Implementation;