import React from 'react';
import {Button, ImageBoxThree, InfoMedia, SecSubTitle, SecTitle} from '../../components';

const AboutThree = () => (
  <div className="about-wrap1 background-image" style={{backgroundImage: 'url(images/bg/ab-bg-2-1.jpg)'}}>
    <div className="container">
      <div className="row gx-60">
        <div className="col-xl-6 mb-50 mb-xl-0">
          <ImageBoxThree 
            imageOne="images/esyncross/eSyncross (18).jpeg"
            imageTwo="images/esyncross/eSyncross (19).jpeg"
            number=" "
            title="Explore Support Portal"
            icon="fal fa-award"
          />
        </div>
        <div className=" col-xl-6 align-self-center text-center text-xl-start">
          <SecSubTitle><i className="fas fa-bring-forward" />WE CARE AFTER</SecSubTitle>
          <SecTitle>Client Care Team</SecTitle>
          <p className="mb-4 pb-2 pe-xl-5">Our commitment to your success doesn't end with implementation. Our client care team provides support and training to ensure you make the most of your ERP investment. Whether you need assistance with system troubleshooting, user training, or ongoing optimization, we're here to help.</p>
          <div className="row gx-60 mb-4 pb-1 gy-2 text-start justify-content-center justify-content-xl-start">
            <div className="col-auto">
              <InfoMedia className="about-media"
                image="images/icon/ab-ic-2-1.png"
                info="Provide Skills Services"
              />
            </div>
            <div className="col-auto">
              <InfoMedia className="about-media"
                image="images/icon/ab-ic-2-2.png"
                info="Urgent Support For Clients"
              />
            </div>
          </div>
          <Button path="/contact">Contact Support team<i className="far fa-arrow-right"/></Button>
        </div>
      </div>
    </div>
  </div>
);

export default AboutThree;