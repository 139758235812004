import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {ContactTwo, FooterOne, HeaderOneCopy} from '../containers';

const Contact = () => (
  <Fragment>
    <Seo title="Contact Us" />
    <HeaderOneCopy />
    <Breadcrumb pageName="Contact Us"
                pageSubTitle={"Get in Touch with Us Today"}
                pageDesc={"Ready to connect? Start a conversation today. We're here to help."}
                bgImage="images/bg/bg-image-6.png" />
    <ContactTwo initialService={"Implementation"} className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default Contact;