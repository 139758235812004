import React, { Fragment } from 'react';
import { Breadcrumb, ScrollTopBtn, Seo } from '../components';
import {
        AboutFour,
        FeatureTwo,
        FooterOne,
        HeaderOneCopy,
        ProcessTwo
} from '../containers';

const features = [
        {
                title: "Custom SAP B1 Integration",
                image: "images/icon/fe-2-1.png",
                text: "Build web applications that seamlessly integrate with SAP B1, enhancing efficiency across warehouses, sales, orders, payments, and more."
        },
        {
                title: "Enterprise Web Solutions",
                image: "images/icon/fe-2-2.png",
                text: "Scalable enterprise web apps, seamless integration with existing systems, robust e-commerce boosting sales via secure SAP B1-integrated storefronts."
        },
        {
                title: "Web Portal Development",
                image: "images/icon/fe-2-4.png",
                text: "Intuitive portals for internal use, customer engagement, or community building, with real-time SAP B1 integration. "
        }
];

const aboutFourData = {
        title: 'Why Choose Us?',
        subtitle: 'TRUSTED SERVICE FROM A TRUSTED PARTNER',
        images:[
                'images/esyncross/eSyncross (43).jpeg',
                'images/esyncross/eSyncross (53).jpeg',
        ],
        points: [
                {
                        title: "Expertise in SAP B1 Integration",
                        text: "Extensive experience in integrating web applications with SAP B1, ensuring seamless operations and data consistency. Our expertise covers various industries, providing tailored solutions that align with your strategic goals."
                },
                {
                        title: "Client-Centric Approach",
                        text: "Close collaboration to understand your business needs deeply, delivering customized solutions that precisely fit your goals and requirements."
                },
                {
                        title: "Support and Maintenance",
                        text: "Comprehensive support to ensure your web application remains secure, optimized, and up-to-date post-launch. We proactively monitor and improve performance to meet evolving business demands."
                }
        ]
};

const processData = [
        {
                "number": "01",
                "arrow": "images/icon/process-arrow-1-1.png",
                "arrow2": "images/icon/process-arrow-2-1.png",
                "icon": "images/icon/process-1-4.png",
                "title": "Consultation and Requirements Analysis",
                "text": "We begin by understanding your business processes and goals to define the scope and requirements of your web application."
        },
        {
                "number": "02",
                "arrow": "images/icon/process-arrow-1-1.png",
                "arrow2": "images/icon/process-arrow-2-1.png",
                "icon": "images/icon/process-1-1.png",
                "title": "Design and Development",
                "text": "Our team designs user-friendly interfaces and develops robust functionalities, seamlessly integrated with SAP B1."
        },
        {
                "number": "03",
                "arrow": "images/icon/process-arrow-1-1.png",
                "arrow2": "images/icon/process-arrow-2-1.png",
                "icon": "images/icon/process-1-2.png",
                "title": "Testing and Quality Assurance",
                "text": "Rigorous testing ensures your web application meets performance standards and provides a flawless user experience."
        },
        {
                "number": "04",
                "arrow": "images/icon/process-arrow-1-1.png",
                "arrow2": "images/icon/process-arrow-2-1.png",
                "icon": "images/icon/process-1-3.png",
                "title": "Deployment and Support",
                "text": "Smooth deployment and ongoing support ensure your web application's performance is monitored, issues are addressed promptly, and updates are implemented as necessary."
        }
];

const WebApps = () => (
    <Fragment>
            <Seo title="Web Applications"/>
            <HeaderOneCopy/>
            <Breadcrumb
                pageName="Web Applications"
                pageSubTitle="EXPAND YOUR ONLINE PRESENCE"
                pageDesc="Developing web applications integrated with SAP Business One to streamline operations."
                bgImage="images/bg/bg-image-2.png"
            />

            <FeatureTwo
                features={features}
                title="Our Services"
                subTitle="Enhance Your Business Operations"
                className="space-top mb-50"
                featuresHeightClass="ht-425"
            />

            <AboutFour
                data={aboutFourData}
                className="space space-top space-bottom mt-5"
            />

            <ProcessTwo
                data={processData}
                className="space-top space-extra-bottom"
            />

            <FooterOne/>
            <ScrollTopBtn/>
    </Fragment>
);

export default WebApps;
