import React from 'react';
import {ProcessBoxOne, SecSubTitle, SecTitle, TitleWrap} from '../../components';

// Process Data
import processData from '../../data/process.json';
import ProcessBoxTwo from "../../components/ProcessBox/ProcessBoxTwo";

const ProcessThree = ({className,data})=> (
  <div className={`process-wrap1 background-image ${className || ''}`} style={{backgroundImage: 'url(images/bg/process-bg-3-1.jpg)'}}>    
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-xl-6">
          <TitleWrap>
            <SecSubTitle>{data.subtitle}</SecSubTitle>
            <SecTitle className="h1">{data.title}</SecTitle>
          </TitleWrap>
        </div>
      </div>
      <div className="row justify-content-center">
        {data.points.map((item,index) => (
          <ProcessBoxTwo key={index} className="col-md-4 col-xl-auto process-style2"
            title={item.title}
            text={item.text} 
            icon={item.icon} 
          />
        ))}
      </div>
    </div>
  </div>
);

export default ProcessThree;