import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {FooterOne, HeaderOneCopy, ServiceSingle, ServicesNew} from '../containers';

const services =  [
            {
                title: 'All Services',
                link: '#',
                icon: 'fas fa-sync',
                subItems: [
                    { objectFit: 'cover', reference: 'SAP Business One', title: 'Transformation & Implementation', link: '/sapb1-implementation', imgPath: '/images/sapb1/sap-b1-transformation.jpeg', description: 'Migrate to SAP B1 based on Best Practises' },
                    { objectFit: 'cover', reference: 'Development Services', title: 'Solution Architecture', link: '/solution-architecture', imgPath: 'images/Solution-Architecture.jpg', description: 'Framework for designing IT solutions.' },
                    { objectFit: 'cover', reference: 'SAP Business One', title: 'Integration', link: '/sapb1-integration', imgPath: '/images/sapb1/sap-b1-integration.jpg', description: 'Integrating SAP B1 with any 3rd party Apps' },
                    { objectFit: 'cover', reference: 'Cloud Management', title: 'Cloud Hosting', link: '/cloud-hosting', imgPath: 'images/cloud/cloud-hosting-1.png', description: 'Hosting services delivered via cloud.' },
                    { objectFit: 'cover', reference: 'SAP Business One', title: 'Addons Development', link: '/sapb1-addon', imgPath: '/images/sapb1/sap-b1-addons.jpeg', description: 'Extend existing function and Create new processes' },
                    { objectFit: 'cover', reference: 'Development Services', title: 'Mobile Applications', link: '/mobile-applications', imgPath: 'images/mobile-app-dev.webp', description: 'Software designed for mobile devices' },
                    { objectFit: 'cover', reference: 'SAP Business One', title: 'Business Analytics', link: '/business-analytics', imgPath: '/images/sapb1/sap-b1-analytics.jpg', description: 'Advanced reports and dashboards' },
                    { objectFit: 'cover', reference: 'Development Services', title: 'Web Applications', link: '/web-applications', imgPath: 'images/web-app-dev.webp', description: 'Internet-based software applications.' },
                    { objectFit: 'cover', reference: 'Cloud Management', title: 'Cloud Migration', link: '/cloud-migration', imgPath: 'images/cloud/cloud-migration-2.png', description: 'Moving applications to cloud infrastructure' }
                ]
            },
            {
                title: 'SAP Business One',
                link: '#',
                icon: 'fas fa-building',
                subItems: [
                    { objectFit: 'cover', reference: 'SAP Business One', title: 'Transformation & Implementation', link: '/sapb1-implementation', imgPath: '/images/sapb1/sap-b1-transformation.jpeg', description: 'Migrate to SAP B1 based on Best Practises' },
                    { objectFit: 'cover', reference: 'SAP Business One', title: 'Integration', link: '/sapb1-integration', imgPath: '/images/sapb1/sap-b1-integration.jpg', description: 'Integrating SAP B1 with any 3rd party Apps' },
                    { objectFit: 'cover', reference: 'SAP Business One', title: 'Addons Development', link: '/sapb1-addon', imgPath: '/images/sapb1/sap-b1-addons.jpeg', description: 'Extend existing function and Create new processes' },
                    { objectFit: 'cover', reference: 'SAP Business One', title: 'Business Analytics', link: '/business-analytics', imgPath: '/images/sapb1/sap-b1-analytics.jpg', description: 'Advanced reports and dashboards' }
                ]
            },
            {
                title: 'Development Services',
                link: '#',
                icon: 'fas fa-map-marker-alt',
                subItems: [
                    { objectFit: 'cover', reference: 'Development Services', title: 'Solution Architecture', link: '/solution-architecture', imgPath: 'images/Solution-Architecture.jpg', description: 'Framework for designing IT solutions.' },
                    { objectFit: 'cover', reference: 'Development Services', title: 'Mobile Applications', link: '/mobile-applications', imgPath: 'images/mobile-app-dev.webp', description: 'Software designed for mobile devices' },
                    { objectFit: 'cover', reference: 'Development Services', title: 'Web Applications', link: '/web-applications', imgPath: 'images/web-app-dev.webp', description: 'Internet-based software applications.' }
                ]
            },
            {
                title: 'Cloud Management',
                link: '#',
                icon: 'fas fa-exchange-alt',
                subItems: [
                    { objectFit: 'cover', reference: 'Cloud Management', title: 'Cloud Hosting', link: '/cloud-hosting', imgPath: 'images/cloud/cloud-hosting-1.png', description: 'Hosting services delivered via cloud.' },
                    { objectFit: 'cover', reference: 'Cloud Management', title: 'Cloud Migration', link: '/cloud-migration', imgPath: 'images/cloud/cloud-migration-2.png', description: 'Moving applications to cloud infrastructure' }
                ]
            }
        ]
const ServiceDetails = ()=> (
  <Fragment>
    <Seo title="Services" />
    <HeaderOneCopy />
    <Breadcrumb
        pageName="Services"
        pageSubTitle={"Enhancing Your Business Through Innovative Solutions"}
        pageDesc={"Discover our services: consulting, implementation, and support to meet your needs and achieve goals efficiently."}
        bgImage="images/bg/bg-image-2.png" />

      <ServicesNew type={"Services"} data={services} className="space-top space-extra-bottom" />
{/*    <ServiceSingle className="space-top space-extra-bottom"/>*/}
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default ServiceDetails;