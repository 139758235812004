import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {FooterOne, HeaderOneCopy} from '../containers';
import SAPB1Video from "../containers/Testimonials/SAPB1Video";
import AboutEight from "../containers/Abouts/AboutEight";
import AboutSevenCopy from "../containers/Abouts/AboutSevenCopy";
import AboutFourCopy from "../containers/Abouts/AboutFourCopy";

const aboutFourData = {
        title:'Make eSyncross Your ERP Partner',
        subtitle:'TRUSTED SERVICE FROM A TRUSTED PARTNER ',
}
const aboutSevenData = [
        {
                flip: false,
                title:'Get to Go-Live With Confidence and Ease',
                subtitle:'TRUST OUR EXPERTISE',
                imgPath: 'images/esyncross/eSyncross (31).jpeg',
                bottomList: ["As one of the very first SAP Business One Partners, eSyncross offers unparalleled experience—there’s nothing we haven’t seen",
                        "Rely on our tried and trusted implementation methodology and enjoy project management support every step of the way",
                        "Future-proof your business with our powerful cloud hosting platform."
                ],
                description: 'An ERP implementation can make or break a business. eSyncross’s approach removes the risk by ensuring careful planning, strong leadership, responsiveness, and transparent communication.'


        },
        {
                flip: true,
                title:'Enjoy Ultimate Extensibility and Flexibility',
                subtitle:'MAKE IT YOUR OWN',
                imgPath: 'images/esyncross/eSyncross (47).jpeg',
                bottomList: ["Integrate seamlessly with previously disconnected systems",
                        "Eliminate data silos, automate manual processes, and unlock new levels of operational efficiency and cost savings",
                        "Create a unique solution that fits your business, not the other way around"
                ],
                description: 'An ERP should work for your business. With SAP Business One and help from our certified consultants, you’ll be able to integrate, customize, and get maximum value from your investment.'


        },
        {
                flip: false,
                title:'Extend Your Investment Beyond Deployment',
                subtitle:'GET MORE VALUE',
                imgPath: 'images/esyncross/eSyncross (20).jpeg',
                bottomList: ["Enjoy timely and helpful support thanks to our flagship TOTAL Care support program",
                        "Get access to a wealth of resources, training programs, and customer-only webinars",
                        "See why eSyncross has customer retention rates that well exceed industry averages"
                ],
                description: 'Your needs don’t end when your deployment is done. eSyncross understands that and offers industry-leading support and value well beyond go-live.'


        },
]
const data = [
        {
                id: 'tab1',
                title: 'Accounting & Financials',
                subtitle: '',
                icon: 'images/icon/sr-icon-3-1.png',
                image: 'images/esyncross/eSyncross (30).jpeg',
                texts: ["Automatically handle all key accounting processes, such as journal entries, accounts receivable, and accounts payable", "Quickly process reconciliations, bank statements, and payments by various methods", "Create standard or customized reports from real-time data for business planning and audit reviews"],
                feature: [],
                btntext: '',
                path: '/service-details'
        },
        {
                id: 'tab2',
                title: 'Sales & Customer Relationship Management (CRM)',
                subtitle: '',
                icon: 'images/icon/sr-icon-3-5.png',
                image: 'images/esyncross/eSyncross (48).jpeg',
                texts: ["Track opportunities and activities from the first contact to deal closing",
                        "Store all critical customer data in one place and synchronize and manage customer contacts stored in Microsoft Outlook",
                        "Create detailed reports on all aspects of the sales process, using time-saving templates"],
                feature: [],
                btntext: '',
                path: '/service-details'
        },
        {
                id: 'tab3',
                title: 'Purchasing & Procurement',
                subtitle: '',
                icon: 'images/icon/sr-icon-3-2.png',
                image: 'images/esyncross/eSyncross (20).jpeg',
                texts: ["Create purchase requests, POs, and goods receipts; link purchasing documents and view document trails for audit purposes; and manage returns, additional expenses, and multiple currencies",
                        "Process accounts payable invoices, cancellations, and credit memos with a PO reference; plan your material needs; and schedule your purchases accordingly",
                        "Generate reports with real-time data and display them in various report formats or dashboards"],
                feature: [],
                btntext: '',
                path: '/service-details'
        },
        {
                id: 'tab4',
                title: 'Warehouse & Production',
                subtitle: '',
                icon: 'images/icon/sr-icon-3-3.png',
                image: 'images/esyncross/eSyncross (9).jpeg',
                texts: ["Manage inventory using various costing models, maintain item master data, and use multiple units of measure and pricing",
                        "Record goods receipts and issues; track stock locations and transfers; enable consignment, drop-ship, and other orders; and perform inventory and cycle counts",
                        "Create and maintain multilevel bills of materials (BOMs), issue and release production orders manually or by backflush, and globally maintain prices for BOMs"],
                feature: [],
                btntext: '',
                path: '/service-details'
        },
        {
                id: 'tab5',
                title: 'Reporting & Analytics',
                subtitle: '',
                icon: 'images/icon/sr-icon-3-4.png',
                image: 'images/esyncross/eSyncross (54).jpeg',
                texts: ["Access data from multiple sources, create new reports, and customize existing reports in a variety of layouts with minimal IT overhead",
                        "Use with standard Microsoft Excel features to create reports and see your business from new angles\n",
                        "Analytics plus pre-defined KPIs help you to see average delivery variance days, top five sales employees, and more\n"],
                feature: [],
                btntext: '',
                path: '/service-details'
        },
]

const SapBusinessOne = () => (
    <Fragment>
        <Seo title="SAP Business One"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="SAP Business One"
                    pageSubTitle={"BECOME A BEST-RUN BUSINESS"}
                    pageDesc={"Manage your business with eSyncross’s powerful, affordable ERP solution."}
                    bgImage="images/bg/bg-sap.png"/>

            <SAPB1Video
                video={"/videos/SAPBusinessOne.mp4"}
                title={"A Flexible ERP to Level Up Your Operations"}
                subTitle={"ABOUT SAP BUSINESS ONE"}
                secTitle={"SAP Business One"}
                description={"SAP Business One is an ERP solution designed for small to midsize businesses, providing tools to manage sales, distribution, and financials. By moving to SAP Business One, customers gain real-time data access, streamlined operations, and better decision-making. Its scalability ensures it grows with your business, while the user-friendly interface enhances productivity. Additionally, powerful reporting and analytics offer deeper insights for strategic growth. Continuous support and innovation from SAP help businesses stay competitive in a changing market."}
                className="space space-extra-bottom"/>

            <AboutEight
                data={data}
                listStyle={'3'}
                title={"SAP Business One"}
                subtitle={"Key Features"}
                className="space-top space-extra-bottom"/>
        <AboutFourCopy data={aboutFourData} className=""/>
            {aboutSevenData?.map((item,index) => <AboutSevenCopy
                index={index}
                flip={item.flip}
                subTitle={item.subtitle}
                title={item.title}
                imgPath={item.imgPath}
                bottomList={item.bottomList}
                description={item.description}
            />)}

        <FooterOne/>
        <ScrollTopBtn/>
    </Fragment>
);

export default SapBusinessOne;