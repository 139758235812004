import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {
        AboutFive,
        AboutFour,
        AboutThree, BrandTwo,
        ContactTwo,
        FeatureTwo,
        FooterOne,
        HeaderOneCopy, ProcessThree,
        ProcessTwo, TestimonialTwo
} from '../containers';
import productsData from '../data/products.json';


const keyFeatures = {
        title:"Features of Our Solution Architecture",
        subtitle:"",
        points:[

                {
                        "title": "Technology Expertise",
                        "text": "Utilize a wide range of technologies including ASP .NET, ASP .NET Core, Entity Framework, React.js, Next.js for web, Java, Android, and React Native for mobile.",
                        "icon": "images/icon/sr-icon-4-1.png"
                },
                {
                        "title": "Thorough Planning",
                        "text": "Conduct a thorough assessment of business needs and IT requirements for comprehensive planning, ensuring optimal solution design.",
                        "icon": "images/icon/process-1-2.png"
                },
                {
                        "title": "Custom Solutions",
                        "text": "Develop tailor-made architectures to address and fit specific business challenges effectively, maximizing operational efficiency and performance.",
                        "icon": "images/icon/sr-icon-3-2.png"
                },
                {
                        "title": "Integration Capabilities",
                        "text": "Provide seamless integration with existing systems and third-party applications for enhanced performance, reducing operational disruptions and costs.",
                        "icon": "images/icon/fe-2-2.png"
                },
                {
                        "title": "Security & Compliance",
                        "text": "Ensure robust security measures and compliance with all relevant industry standards, protecting data and maintaining trust.",
                        "icon": "images/icon/process-2-2.png"
                },
                {
                        "title": "Future-Proofing",
                        "text": "Design adaptable solutions to accommodate future technological advancements and evolving business needs, ensuring long-term scalability and success.",
                        "icon": "images/icon/sr-icon-3-4.png"
                }
        ]


}


const aboutFourData = {
        title:'Why Choose Us?',
        subtitle:'All Your needs in our solutions',
        images:[
          "/images/solutions/solutions-1.jpeg",
          "/images/solutions/solutions-2.jpg",
        ],
        points: [
                {
                        title: "Experienced Architects",
                        text: "Our team of experienced solution architects brings deep technical expertise and industry knowledge."
                },
                {
                        title: "Proven Methodologies",
                        text: " Apply best practices and proven methodologies for architecture design."
                },
                {
                        title: "Cross-Platform Proficiency",
                        text: "Expertise in both web and mobile technologies to provide comprehensive solutions."
                },
                {
                        title: "Client-Centric Approach",
                        text: "Focus on understanding and fulfilling your specific business needs."
                },
                {
                        title: "Continuous Support",
                        text: "Ongoing support to ensure your IT architecture remains robust and up-to-date."
                },

        ]


}

const SolutionArchitecture = () => (
    <Fragment>
        <Seo title="Solution Architecture"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="Solution Architecture"
                    pageSubTitle={"Robust framework for designing IT solutions"}
                    pageDesc={"Our Solution Architecture service builds scalable, efficient IT infrastructure for your growth needs."}
                    bgImage="images/bg/bg-image-2.png"/>



            <AboutFour reverse={false} data={aboutFourData} className="space space-top mt-5"/>

            <ProcessThree data={keyFeatures} className="space-top space-extra-bottom"/>



        <FooterOne/>

        <ScrollTopBtn/>
    </Fragment>
);

export default SolutionArchitecture;