const Loader = () => (
	<div className="preloader">
		<div className="preloader-inner">
			<div className="loader"></div>
		</div>
	</div>
);

Loader.defaultProps = {};

export default Loader;
