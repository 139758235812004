import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Blog, Pagination} from '../../components/';

// Blog Data
import careerdata from '../../data/career.json';
import SidebarThree from "../Sidebars/SidebarThree";

const Career = ({className}) => {

    const [careersToView, setCareersToView] = useState(careerdata);
    const handleSearch = (text) => {
        setCareersToView(careerdata?.filter(career =>
            career?.title?.toLowerCase()?.includes(text?.toLowerCase()) ||
            career?.text?.toLowerCase()?.includes(text?.toLowerCase())
        ))
    }
    return (
        <section className={`vs-blog-wrapper ${className || ''}`}>
            <div className="container">
                <div className="row gx-40">
                    <div className="col-lg-8">
                        {careersToView.slice(0, 5).map(career => (
                            <Blog className="blog-single" key={career.id}>
                                <Blog.Image path={`/career-details/${career.id}`} src={career.image}/>
                                <Blog.Body>
                                    <Blog.Meta>
                                        <Link to="/careers"><i className="fal fa-user"/>HR team</Link>
                                    </Blog.Meta>
                                    <Blog.Title path={`/career-details/${career.id}`}>{career.title}</Blog.Title>
                                    <p>{career.text}</p>
                                    <Link to={"/career-details/"+career?.id} className="link-btn">Read Details <i
                                        className="far fa-arrow-right"></i></Link>
                                </Blog.Body>
                            </Blog>
                        ))}
                        {/*<Pagination/>*/}
                    </div>
                    <div className="col-lg-4">
                        <SidebarThree handleSearch={handleSearch}/>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Career;