import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {
    AboutFive,
    AboutFour,
    AboutThree, BrandTwo,
    ContactTwo, FaqOne,
    FeatureTwo,
    FooterOne,
    HeaderOneCopy, ProcessThree,
    ProcessTwo, TestimonialTwo
} from '../containers';
import productsData from '../data/products.json';
import EVOData from "../data/EVO.json";
import AboutEight from "../containers/Abouts/AboutEight";
import AboutEightWithSideTabs from "../containers/Abouts/AboutEightWithSideTabs";
import FaqTwo from "../containers/Faqs/FaqTwo";
import AboutSevenCopy from "../containers/Abouts/AboutSevenCopy";
import addonReports from "../data/addonReports.json";




const processData = [
    {
        "number": "01",
        "arrow": "images/icon/process-arrow-1-1.png",
        "arrow2": "images/icon/process-arrow-2-1.png",
        "icon": "images/icon/process-1-4.png",
        "title": "Points of Exit",
        "text": "Identify locations where goods are officially allowed to leave the country."
    },
    {
        "number": "02",
        "arrow": "images/icon/process-arrow-1-1.png",
        "arrow2": "images/icon/process-arrow-2-1.png",
        "icon": "images/icon/process-1-1.png",
        "title": "Declaration Types",
        "text": "Determine the forms or documents required to provide details about the goods."
    },
    {
        "number": "03",
        "arrow": "images/icon/process-arrow-1-1.png",
        "arrow2": "images/icon/process-arrow-2-1.png",
        "icon": "images/icon/process-1-2.png",
        "title": "Port of Destination",
        "text": "Specify the final port where goods are offloaded and cleared through customs."
    },
    {
        "number": "04",
        "arrow": "images/icon/process-arrow-1-1.png",
        "arrow2": "images/icon/process-arrow-2-1.png",
        "icon": "images/icon/process-1-3.png",
        "title": "Port of Loading",
        "text": "Identify the port where goods are loaded for export, marking the departure point."
    },
    {
        "number": "05",
        "arrow": "images/icon/process-arrow-1-1.png",
        "arrow2": "images/icon/process-arrow-2-1.png",
        "icon": "images/icon/process-1-4.png",
        "title": "Cargo Types",
        "text": "Classify goods based on nature, size, and handling requirements."
    },
    {
        "number": "06",
        "arrow": "images/icon/process-arrow-1-1.png",
        "arrow2": "images/icon/process-arrow-2-1.png",
        "icon": "images/icon/process-1-1.png",
        "title": "Incoterms",
        "text": "Apply terms defining responsibilities for delivery, risk, and costs."
    },
    {
        "number": "07",
        "arrow": "images/icon/process-arrow-1-1.png",
        "arrow2": "images/icon/process-arrow-2-1.png",
        "icon": "images/icon/process-1-2.png",
        "title": "Forwarder",
        "text": "Organize shipments and handle logistics to move goods from origin to destination."
    },
    {
        "number": "08",
        "arrow": "images/icon/process-arrow-1-1.png",
        "arrow2": "images/icon/process-arrow-2-1.png",
        "icon": "images/icon/process-1-3.png",
        "title": "Additional Expenses",
        "text": "Track extra costs such as duties, taxes, and storage fees beyond basic charges."
    }
]

const faq = {
    title: "ENHANCED CUSTOMS MANAGEMENT TOOLS",
    subtitle: "Efficiency, Accuracy, Compliance tailored to your trade operations",
    points: [
        {
            id: 0,
            title: "Streamlining Import/Export Documentation",
            text: " Simplifies the management of shipping documents, ensuring compliance with customs requirements.",
        },
        {
            id: 1,
            title: "Facilitating Accurate Tracking",
            text: "Offers detailed tabs for item, shipment, container, and cost information, enabling precise monitoring of goods.",
        },
        {
            id: 2,
            title: "Custom Exchange Rates",
            text: " Incorporates authority-specific exchange rates for accurate financial documentation.",
        },
        {
            id: 3,
            title: "Efficiency in Customs Processing",
            text: "Designed to improve the speed and accuracy of customs clearance for both imports and exports.",
        }
    ]
};


const aboutSevenData = [
    {
        "flip": true,
        "title": "Exchange Rates",
        "subtitle": "ACCURATE AND AUTHORITY-APPROVED RATES",
        "imgPath": "images/addon/addon-1.jpg",
        "bottomList": [
            "Utilize authority-provided exchange rates to ensure accuracy.",
            "Apply the appropriate exchange rates to each document as required.",
            "Manage discrepancies between SAP's standard rates and authority rates seamlessly."
        ],
        "description": "The exchange rates screen in the customs addon ensures the application of accurate, authority-approved exchange rates to each document, addressing discrepancies with SAP's standard rates for precise customs processing and compliance."
    },
    {
        "flip": false,
        "title": "Shipping Document",
        "subtitle": "STREAMLINE YOUR CUSTOMS PROCESSING",
        "imgPath": "images/addon/addon-2.jpg",
        "bottomList": [
            "Capture item details accurately from purchase orders and adjust based on received documents.",
            "Fill in precise shipping specifics, including dates, transport modes, and carrier information.",
            "Document container specifics vital for logistics and customs clearance.",
            "Track additional expenses like insurance, freight, duties, and taxes for comprehensive cost overview."
        ],
        "description": "The customs addon's 'Shipping Documents' screen ensures efficient customs processing and compliance by accurately tracking shipping details, container specifics, and associated costs."
    },
    {
        flip: true,
        "title": "Bill of Entry",
        "subtitle": "COMPREHENSIVE IMPORT TRANSACTION MANAGEMENT",
        "imgPath": "images/addon/addon-3.png",
        "bottomList": [
            "Capture item details from purchase orders and adjust to match actual received documents.",
            "Input crucial bill of entry information, including entry numbers, import dates, and clearance details.",
            "Record detailed container information essential for logistics and customs clearance.",
            "Log additional expenses like insurance, freight, customs duties, and taxes for accurate financial documentation."
        ],
        "description": "The 'Bill of Entries' interface in the customs addon ensures meticulous documentation and management of import transactions, facilitating efficient customs processing and compliance with precise tracking of items, bill of entry details, container information, and additional expenses."
    },
    {
        flip: false,
        "title": "Bill of Exit",
        "subtitle": "EFFORTLESS EXPORT DOCUMENTATION",
        "imgPath": "images/esyncross/eSyncross (30).jpeg",
        "bottomList": [
            "Capture specifics from export orders or invoices and adjust to match the actual exported items.",
            "Record essential export declaration information, including Bill of Exit numbers, export dates, and destinations."
        ],
        "description": "The 'Bill of Exit' feature in the customs addon ensures efficient export documentation by meticulously capturing item details and critical export declaration information, facilitating smooth customs processing and compliance."
    },
]


const CustomAddon = () => (
    <Fragment>
        <Seo title="Customs Addon"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="Customs Addon"
                    pageSubTitle={"Enhance SAP B1 with Customs Addon"}
                    pageDesc={"Enhances efficiency and compliance in international trade operations." }
                    bgImage="images/bg/bg-image-2.png"/>

        {/*<ProcessTwo data={processData} className="space-top space-extra-bottom"/>*/}

        <FaqTwo
            data={faq}
            className={"why-choose-us space-bottom mb-5"}/>

        <TestimonialTwo
            title={"Comprehensive Reporting and Documentation"}
            subtitle={"Streamline Your Trade Processes"}
            data={addonReports} className="space-top space-extra-bottom"/>

        {aboutSevenData?.map((item,index)  => <AboutSevenCopy
            index={index}
            flip={item.flip}
            subTitle={item.subtitle}
            title={item.title}
            imgPath={item.imgPath}
            bottomList={item.bottomList}
            description={item.description}
        />)}




        <FooterOne/>

        <ScrollTopBtn/>
    </Fragment>
);

export default CustomAddon;